import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import { getShowPricePromiseModalSelector } from '../../redux/reducers/app/appSelector';
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';

export const getPricePromiseFeatureActiveSelector =
  (state: RootState) => isFeatureActive(state, featureConstants.PRICE_PROMISE);

const pricePromiseState = createSelector([
  getPricePromiseFeatureActiveSelector,
  getShowPricePromiseModalSelector,
], (
  pricePromiseFeatureActive,
  showPricePromiseModal,
) => ({
  pricePromiseFeatureActive,
  showPricePromiseModal,
}));

export default pricePromiseState;
