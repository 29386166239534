import { lazy } from 'react';

// Types
import { MembershipNotificationProps as LazyComponentProps } from './MembershipNotification.types';

// Components
import withSuspense, { lazyRetry } from '../../suspense';

const LazyComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: "membershipNotification" */ './MembershipNotification')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'MembershipNotification');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as MembershipNotification };
