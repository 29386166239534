import { AnyAction } from 'redux';
import isEqual from 'lodash/isEqual';

// Types
import { AddressRecordProps } from 'types/Address.types';
import { DeliveryState } from 'types/RootState.types';

// Config
import { validateAddressBookRecords } from '../../../utils/addressBook/validateAddressBookRecords';

function setConfirmedDeliveryAddress(state: DeliveryState, action: AnyAction) {
  if (action.address) {
    // customer has chosen a new address
    return action.address;
  }

  if (state.confirmedDeliveryAddress) {
    // customer has already chosen a delivery address
    return state.confirmedDeliveryAddress;
  }

  // try and find the customer's address from the address provided in API response
  const addressBook: AddressRecordProps[] = action?.result?.customer?.addressBook ?? [];
  const addressFromResponse = action?.result?.orderForm?.deliveryAddress ?? {};

  const foundAddress = addressBook?.find(
    (addressRecord) =>
      isEqual(addressRecord.address, addressFromResponse.address) &&
      isEqual(addressRecord.addressee, addressFromResponse.addressee) &&
      isEqual(addressRecord.phoneNumber, addressFromResponse.phoneNumber),
  );

  if (foundAddress) {
    const validatedAddressList = validateAddressBookRecords([foundAddress]);
    const validatedAddress = validatedAddressList[0] ?? {};

    if (!validatedAddress.invalid && !validatedAddress.notAvailableForDelivery) {
      return validatedAddress;
    }
  }

  return undefined;
}

export default setConfirmedDeliveryAddress;
