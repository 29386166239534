import React, { ExoticComponent, Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Types
import { AppDispatch } from 'types/RootState.types';

// Config
import { finishLazyComponentLoad, startLazyComponentLoad } from '../../redux/actions/app/appActions';
import { getStorageItem, setStorageItem } from '../../utils/storage/storage';

// Components
import ErrorSafeguard from '../error-safeguard';

export function lazyRetry<T>(componentImport: () => Promise<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed-${btoa(componentImport.toString())}`;
    let hasRefreshed = false;
    try {
      hasRefreshed = JSON.parse(getStorageItem(storageKey) || 'false');
    } catch (error) {
      hasRefreshed = true;
    }

    function setLocalStorageItem(value = 'true') {
      setStorageItem({ key: storageKey, value });
    }

    componentImport()
      .then((component) => {
        setLocalStorageItem('false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          setLocalStorageItem();
          window.location.reload();
        } else {
          reject(error);
        }
      });
  });
}

const defaultFallback = <div data-testid="suspense-fallback" />;

function withSuspense<T>(
  LazyComponent: ExoticComponent<React.ComponentPropsWithRef<any>>,
  ComponentName: string,
  Fallback: React.ReactNode = defaultFallback,
) {
  return function SuspenseComponent(props: T) {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [, forceRetry] = useState<number>(0);
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
      dispatch(startLazyComponentLoad(ComponentName));
    }, [dispatch]);

    useEffect(() => {
      if (isLoaded) {
        dispatch(finishLazyComponentLoad(ComponentName));
      }
    }, [isLoaded, dispatch]);

    const handleOnLoad = () => setIsLoaded(true);

    return (
      <ErrorSafeguard retryLoadingComponent={() => forceRetry((retry) => retry + 1)}>
        <Suspense fallback={Fallback}>
          <LazyComponent {...props} onLoad={handleOnLoad} />
        </Suspense>
      </ErrorSafeguard>
    );
  };
}

export default withSuspense;
