import { createSelector } from 'reselect';

// Config
import { getIsApplicationSelector } from '../../redux/reducers/app/appSelector';
import { getBasketUrlSelector } from '../../redux/reducers/bff/bffSelector';
import {
  getRebatchOrderApiCallActiveSelector,
  getRemoveBasketItemsApiCallActiveSelector,
  getRemoveBasketItemsFailedSelector,
} from '../../redux/reducers/order-form/orderFormSelector';
import {
  getAgeCheckErrorSelector,
  getAgeCheckMinimumAgeSelector,
  getAgeRestrictedProductsCanRemoveSelector,
  getAgeRestrictedProductsSelector,
  getIsSignedInSelector,
  getShowAgeCheckModalSelector,
  getUserDobSelector,
} from '../../redux/reducers/user/userSelector';

const getHasSavedDobSelector = createSelector([getUserDobSelector], (userDob) => !!userDob);

const ageCheckModalState = createSelector(
  [
    getBasketUrlSelector,
    getRemoveBasketItemsApiCallActiveSelector,
    getRemoveBasketItemsFailedSelector,
    getRebatchOrderApiCallActiveSelector,
    getAgeCheckErrorSelector,
    getAgeCheckMinimumAgeSelector,
    getAgeRestrictedProductsSelector,
    getAgeRestrictedProductsCanRemoveSelector,
    getIsSignedInSelector,
    getShowAgeCheckModalSelector,
    getHasSavedDobSelector,
    getIsApplicationSelector,
  ],
  (
    basketUrl,
    removeBasketItemsApiCallActive,
    removeBasketItemsFailed,
    rebatchOrderApiCallActive,
    ageCheckError,
    ageCheckMinimumAge,
    ageRestrictedProducts,
    ageRestrictedProductsCanRemove,
    isSignedIn,
    showModal,
    hasSavedDob,
    isApplication,
  ) => ({
    basketUrl,
    removeBasketItemsApiCallActive,
    removeBasketItemsFailed,
    rebatchOrderApiCallActive,
    ageCheckError,
    ageCheckMinimumAge,
    ageRestrictedProducts,
    ageRestrictedProductsCanRemove,
    isSignedIn,
    showModal,
    hasSavedDob,
    isApplication,
  }),
);

export default ageCheckModalState;
