import { AnyAction } from 'redux';
import get from 'lodash/get';

// Types
import { BffState } from 'types/RootState.types';

// Config
import {
  APPS_AUTHENTICATED_HANDOVER,
  APPS_GUEST_HANDOVER,
  HAND_OVER,
  AUTH0_UNAVAILABLE,
  SET_FAKE_SESSION_EXPIRY,
} from '../../../constants/actionConstants';
import urlConstants from '../../../constants/urlConstants';

export const INITIAL_STATE = {
  sessionHandoverComplete: false,
  sessionHandoverFailed: false,
  browseUrl: urlConstants.HOME_PAGE_URL,
  basketUrl: urlConstants.BASKET_PAGE_URL,
  sessionExpiryData: undefined,
  sessionId: '',
};

function appendPropsFromHeaders(action: AnyAction, state: BffState) {
  const props = get(action, 'result.propsFromHeaders');
  if (props) {
    const { sessionId, sessionExpiryData } = props;
    return {
      ...state,
      ...(sessionExpiryData ? { sessionExpiryData } : {}),
      ...(sessionId ? { sessionId } : {}),
    };
  }

  return state;
}

export default function reducer(state: BffState = INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case SET_FAKE_SESSION_EXPIRY: {
      const { sessionExpiresIn = '20000', sessionWarningAfterTime = 2000 } = action;
      return {
        ...state,
        sessionExpiryData: {
          sessionExpiresIn,
          sessionWarningAfterTime,
          timestamp: Date.parse(new Date().toString()),
        },
      };
    }

    case `${APPS_GUEST_HANDOVER}.SUCCESS`:
    case `${APPS_AUTHENTICATED_HANDOVER}.SUCCESS`:
    case `${HAND_OVER}.SUCCESS`:
      return appendPropsFromHeaders(action, {
        ...state,
        sessionHandoverComplete: true,
        sessionHandoverFailed: false,
      });

    case `${APPS_GUEST_HANDOVER}.FAILED`:
    case `${APPS_AUTHENTICATED_HANDOVER}.FAILED`:
    case `${HAND_OVER}.FAILED`:
    case AUTH0_UNAVAILABLE:
      return appendPropsFromHeaders(action, {
        ...state,
        sessionHandoverFailed: true,
      });

    default:
      return appendPropsFromHeaders(action, state);
  }
}
