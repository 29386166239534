import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from './views/error-boundary/ErrorBoundary';

// Config
import { RootProps } from './Root.types';
import routes from './routes';

const Root = ({ store, persistor, history }: RootProps) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ErrorBoundary>
          {routes(persistor)}
        </ErrorBoundary>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default Root;
