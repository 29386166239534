import { AnyAction } from 'redux';

// Design System
import env from 'jl-design-system/utils/env/env';

// Types
import { DiagnosticsState } from 'types/RootState.types';

// Config
import { SET_LOG_ANALYTICS } from '../../../constants/actionConstants';
import featureConstants from '../../../constants/featureConstants';
import { setStorageItem, getStorageItem } from '../../../utils/storage/storage';

const INITIAL_STATE = {
  logAnalytics: getStorageItem('logAnalytics', true) || '',
};

export default function reducer(state: DiagnosticsState = INITIAL_STATE, action: AnyAction) {
  const actionType = action.type || '';

  if (env.isClientNonProd && actionType === SET_LOG_ANALYTICS) {
    setStorageItem({
      key: featureConstants.LOG_ANALYTICS,
      value: action.flag,
      local: true,
    });
    return {
      ...state,
      logAnalytics: action.flag,
    };
  }

  return state;
}
