/* eslint-disable max-len */
import { lazy } from 'react';

export const MainLazy = lazy(() => import('../../views/main/Main' /* webpackChunkName: "main" */ /* webpackPrefetch: true */));
export const SinglePageSwitchLazy = lazy(() => import('../../views/single-page-switch/SinglePageSwitch' /* webpackChunkName: "main" */ /* webpackPrefetch: true */));
export const OrderConfirmationLazy = lazy(() => import('../../views/order-confirmation/OrderConfirmation' /* webpackChunkName: "orderConfirmation" */));
export const Auth0CallbackGuestLazy = lazy(() => import('../../views/auth0-callback/Auth0CallbackGuest' /* webpackChunkName: "main" */ /* webpackPrefetch: true */));
export const Auth0CallbackAuthenticatedLazy = lazy(() => import('../../views/auth0-callback/Auth0CallbackAuthenticated' /* webpackChunkName: "main" */ /* webpackPrefetch: true */));
export const Auth0CallbackOrderClaimLazy = lazy(() => import('../../views/auth0-callback/Auth0CallbackOrderClaim' /* webpackChunkName: "main" */ /* webpackPrefetch: true */));
export const Auth0CallbackCreateAccountLazy = lazy(() => import('../../views/auth0-callback/Auth0CallbackCreateAccount' /* webpackChunkName: "main" */ /* webpackPrefetch: true */));
export const AgeVerificationCallbackLazy = lazy(() => import('../../views/age/AgeVerificationCallback' /* webpackChunkName: "main" */ /* webpackPrefetch: true */));

export const INTERNATIONAL_PHONE_NUMBER = { id: 'phoneNumber', name: 'phoneNumber' };
export function InternationalPhoneNumberLoader() {
  const cache: {
    isLoading?: boolean;
    module?: any;
    placeholder?: { id?: string; name?: string };
  } = {
    isLoading: false,
    placeholder: INTERNATIONAL_PHONE_NUMBER,
  };

  return function lazy({
    onComplete = () => null,
    onError = () => null,
  } = {}) {

    if (cache.module) {
      return cache.module;
    }

    if (cache.isLoading) {
      return cache.placeholder;
    }

    cache.isLoading = true;

    import('jl-design-system/form/fields/internationalPhoneNumber' /* webpackPrefetch: true */ /* webpackChunkName: "main" */).then((module) => {
      cache.module = module.default;
      cache.isLoading = false;
      onComplete();
    }).catch((error) => {
      /* eslint-disable no-console */
      console.warn('========== LAZY MODULE ERROR', error);
      cache.isLoading = false;
      onError();
    });

    return cache.placeholder;
  };

}

export const internationalPhoneNumberLazy = InternationalPhoneNumberLoader();
