import { lazy } from 'react';

// Types
import { SessionTimeoutModalProps as LazyComponentProps } from './SessionTimeoutModal.types';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: "sessionTimeoutModal" */ './SessionTimeoutModal')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'SessionTimeoutModal');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as SessionTimeoutModal };
