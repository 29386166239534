// Design System
import env from 'jl-design-system/utils/env/env';

// Config
import fetchWithTimeout from '../helpers/fetchWithTimeout';
import appConstants from '../../constants/appConstants';
import { EMAIL_VALIDATION_SERVICE_INT, EMAIL_VALIDATION_SERVICE_PROD } from '../../constants/endpointConstants';

export default async function validateEmail({
  email,
  timeout = appConstants.BFF_TIMEOUT,
}: {
  email: string;
  timeout?: number;
}): Promise<boolean> {
  if (!env.isClientLocal && !env.isClientDev) {
    const endpoint = window?.location?.host?.includes('johnlewis')
      ? EMAIL_VALIDATION_SERVICE_PROD
      : EMAIL_VALIDATION_SERVICE_INT;

    try {
      const response = await fetchWithTimeout(endpoint, {
        method: 'POST',
        timeout,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });

      return response.status === 204;
    } catch (error) {
      return true;
    }
  }

  return email !== 'test@test.comm';
}
