import {
  FIRST_NAME_DATA_STANDARDS,
  LAST_NAME_DATA_STANDARDS,
} from 'jl-design-system/form/fields';
import getAddressFieldsConfig from 'jl-design-system/form/configs/address';
import {
  countryCodeIsInternational,
} from '../../address/addressHelpers';

import makePostcodeOptional from '../../address/makePostcodeOptional';
import makePostcodeReadOnly from '../../address/makePostcodeReadOnly';
import getInternationalPhoneNumberConfig from '../../lazy/internationalPhoneNumberConfig';

export const PAYPAL_BILLING_ADDRESS_FORM_ID = 'paypalBillingDetailsForm';
export const PAYPAL_SHIPPING_ADDRESS_FORM_ID = 'paypalShippingDetailsForm';

export const FORM_ID = {
  billing: PAYPAL_BILLING_ADDRESS_FORM_ID,
  shipping: PAYPAL_SHIPPING_ADDRESS_FORM_ID,
};

// TODO could this be subsumed into billingAddress.js?
export default function getPayPalAddressDetailsFormConfig(params) {
  const {
    countryCode,
    addressLines = 3,
    maxAddressLines,
    actions,
    configExtras = {},
    international,
    enableGBCounty,
    isPaypalExpress,
  } = params;

  const {
    addressType = 'billing',
    isClickCollectOrder,
    submitButtonExtra,
  } = configExtras;

  const ADDRESS_FIELDS = getAddressFieldsConfig({
    international,
    countryCode,
    addressLines,
    maxAddressLines,
    actions,
    configExtras,
    allowAsciiOnly: true,
    enableGBCounty,
  });

  const PHONE_NUMBER = getInternationalPhoneNumberConfig({
    isClickCollectOrder,
    showDefaultInstruction: true,
  });

  const postcodeOptional = countryCodeIsInternational(countryCode);
  if (postcodeOptional) {
    makePostcodeOptional(ADDRESS_FIELDS);
  }

  if (isPaypalExpress) {
    makePostcodeReadOnly(ADDRESS_FIELDS);
  }

  return {
    submitButton: 'Continue with payment',
    submitButtonExtra,
    id: FORM_ID[addressType],
    fields: [
      FIRST_NAME_DATA_STANDARDS,
      LAST_NAME_DATA_STANDARDS,
      PHONE_NUMBER,
      ...ADDRESS_FIELDS,
    ],
  };
}
