import { PureComponent } from 'react';
import PropTypes from 'prop-types';
//
import { connect } from 'react-redux';
import {
  announceSessionExpiring,
  announceSessionExpired,
  getSessionTimeoutValues,
} from '../../redux/actions/app/appActions';

export class SessionExpiryCounter extends PureComponent {

  static propTypes = {
    announceSessionExpired: PropTypes.func,
    announceSessionExpiring: PropTypes.func,
    sessionExpiredAnnounced: PropTypes.bool,
    sessionExpiringAnnounced: PropTypes.bool,
    sessionExpiryData: PropTypes.shape({
      sessionExpiresIn: PropTypes.string,
      sessionWarningAfterTime: PropTypes.number,
    }),
    sessionWarningAfterTime: PropTypes.number,
  };

  static defaultProps = {
    announceSessionExpired: window.defaultFunc,
    announceSessionExpiring: window.defaultFunc,
    sessionExpiredAnnounced: false,
    sessionExpiringAnnounced: false,
    sessionExpiryData: {},
    sessionWarningAfterTime: 0,
  };

  static startedAt = undefined;

  componentDidMount() {
    this.sessionCountdown();
  }

  componentDidUpdate() {
    const {
      sessionExpiringAnnounced,
    } = this.props;

    if (!sessionExpiringAnnounced) {
      window.cancelAnimationFrame(this.countdownInterval);
      this.sessionCountdown();
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.countdownInterval);
  }

  sessionCountdown = () => {
    const {
      sessionExpiryData,
      sessionWarningAfterTime,
    } = this.props;

    if (!sessionExpiryData?.sessionExpiresIn || !sessionWarningAfterTime) {
      return;
    }

    this.startedAt = Date.now();
    this.countdownInterval = window.requestAnimationFrame(this.count);
  };

  count = () => {
    const {
      announceSessionExpiring,
      announceSessionExpired,
      sessionExpiringAnnounced,
      sessionExpiredAnnounced,
      sessionExpiryData,
      sessionWarningAfterTime,
    } = this.props;

    if (sessionExpiredAnnounced) {
      return;
    }

    const elapsedTime = Date.now() - this.startedAt;
    const sessionIsExpiring = elapsedTime >= (sessionExpiryData.sessionWarningAfterTime || sessionWarningAfterTime);
    const sessionHasExpired = elapsedTime >= sessionExpiryData.sessionExpiresIn;

    if (sessionHasExpired) {
      window.cancelAnimationFrame(this.countdownInterval);
      announceSessionExpired();
      return;
    }

    if (sessionIsExpiring && !sessionExpiringAnnounced) {
      announceSessionExpiring();
    }

    this.countdownInterval = window.requestAnimationFrame(this.count);
  };

  render() {
    return null;
  }
}

export function mapStateToProps(state) {
  const stateObject = {
    sessionExpiringAnnounced: state.app.sessionExpiringAnnounced,
    sessionExpiredAnnounced: state.app.sessionExpiredAnnounced,
  };

  return {
    ...stateObject,
    ...getSessionTimeoutValues(state),
  };
}

export function mapDispatchToProps() {
  return {
    announceSessionExpiring,
    announceSessionExpired,
  };
}

export default connect(state => mapStateToProps(state), mapDispatchToProps())(SessionExpiryCounter);
