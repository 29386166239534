import { lazy } from 'react';

// Types
import { ToastRackProps as LazyComponentProps } from './ToastRack.types';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: "toastRack" */ './ToastRack')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'ToastRack');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as ToastRack };
