import { lazy } from 'react';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: "unavailableItemsModal" */ './UnavailableItemsModal')));

const LazyComponentWithSuspense = withSuspense<{}>(LazyComponent, 'UnavailableItemsModal');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as UnavailableItemsModal };
