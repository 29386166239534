import unionBy from 'lodash/unionBy';

// Types
import { FeatureFlagType } from 'types/FeatureFlag.types';

const unionWithDevFeatures = (bffFeatures: FeatureFlagType[]): FeatureFlagType[] => {
  const localFeatures: FeatureFlagType[] = window.env.FEATURES || [];

  const updatedLocalFeatures = localFeatures.map((localFeature) => {
    const bffFeature = bffFeatures.find((feature) => feature.id === localFeature.id && feature.impressionId);
    return bffFeature
      ? { ...localFeature, impressionId: bffFeature.impressionId }
      : localFeature;
  });

  return unionBy(updatedLocalFeatures, bffFeatures, 'id');
};

export default unionWithDevFeatures;
