import { lazy } from 'react';

// Types
import { DevConsoleProps as LazyComponentProps } from './DevConsole.types';

// Components
import withSuspense, { lazyRetry } from '../../../components/suspense';

const LazyComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: "devConsole" */ './DevConsole')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'DevConsole');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as DevConsole };
