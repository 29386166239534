// Types
import { AppDispatch } from 'types/RootState.types';

// Config
import { LAZY_COMPONENT_WITH_SUSPENSE } from '../../../constants/actionConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import { reportErrorToNewRelic } from '../../../utils/logging/logging-utils';

export const triggerLazyComponentWithSuspenseFailure = ({ error }: { error: Error }) => (dispatch: AppDispatch) => {
  if (error) {
    dispatch(reportErrorToNewRelic({ error, errorDescription: 'lazy component with suspense error' }));
  }

  dispatch({
    type: `${LAZY_COMPONENT_WITH_SUSPENSE}.FAILED`,
    error: {
      code: errorCodeConstants.LAZY_COMPONENT_WITH_SUSPENSE_ERROR,
    },
  });
};
