import { useSelector } from 'react-redux';

// Config
import ageCheckerState from './AgeChecker.state';

// Components
import AgeCheckModal from '../age-check-modal';
import YotiModal from '../yoti-modal';
import YotiModalConfirmation from '../yoti-modal/confirmation';

const AgeChecker = () => {
  const {
    ageVerificationProducts,
    featureYotiActive,
    isSignedIn,
    itemsNeedingVerification,
    showAgeCheckModal,
    showYotiConfirmationModal,
    yotiCheckSuccess,
    yotiSessionId,
  } = useSelector(ageCheckerState);

  if (yotiCheckSuccess) {
    if (showYotiConfirmationModal) {
      return <YotiModalConfirmation />;
    }
  } else {
    // Online Identity Verification: Yoti visual verification.
    if (ageVerificationProducts?.length) {
      return (
        <YotiModal
          ageVerificationProducts={ageVerificationProducts}
          featureYotiActive={featureYotiActive}
          isSignedIn={isSignedIn}
          itemsNeedingVerification={itemsNeedingVerification}
        />
      );
    }

    // Honour based: DOB age check.
    if (showAgeCheckModal) {
      return <AgeCheckModal showStepIndicator={!!yotiSessionId} />;
    }
  }

  return null;
};

export default AgeChecker;
