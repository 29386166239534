// Design System
import { destroyForm } from 'jl-design-system/redux/actions/form/formActions';

// Types
import { AppClient, AppDispatch, AppGetState } from 'types/RootState.types';

// Config
import { getFeatures } from '../bff/bffActions';
import { initDeliveryPage } from '../delivery/deliveryActions';
import { resetApp } from '../reset/resetActions';
import { isApps } from '../../reducers/app/appReducer';
import {
  GET_LOGIN_PAGE,
  LOGIN_CREDS,
  LOGIN_RESET,
  SHOW_EMAIL_VALIDATION_ERROR,
} from '../../../constants/actionConstants';
import {
  URL_LOGIN_PAGE_AUTH0_FALLBACK,
  URL_LOGIN_SESSIONS_V2,
  URL_LOGIN_SESSIONS_V2_WITH_LOGIN_TYPE,
} from '../../../constants/endpointConstants';
import loginTypeConstants from '../../../constants/loginTypeConstants';
import validateEmail from '../../../utils/email-validation/emailValidatorProxy';
import { GUEST_FORM_ID } from '../../../utils/form/configs/login';
import { initLogging } from '../../../utils/logging/logging-utils';
import { removeStorageItem } from '../../../utils/storage/storage';

export const getFallbackLoginPage = (auth0Fallback: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
  if (getState()?.app?.orderComplete ?? false) {
    return {};
  }

  return dispatch({
    type: GET_LOGIN_PAGE,
    request: (client: AppClient) =>
      client({
        path: URL_LOGIN_PAGE_AUTH0_FALLBACK(auth0Fallback),
        config: { method: 'GET' },
      }),
  });
};

export const loginCredentials = (loginParams = {}, getState: AppGetState) => {
  let loginType = getState()?.login?.loginType;
  if (loginType === undefined) {
    if (isApps(getState())) {
      loginType = loginTypeConstants.CUSTOMER_APPS;
    }
  }
  const loginUrl = loginType === undefined ? URL_LOGIN_SESSIONS_V2 : URL_LOGIN_SESSIONS_V2_WITH_LOGIN_TYPE(loginType);
  return {
    type: LOGIN_CREDS,
    request: (client: AppClient) => client({ path: loginUrl, config: { method: 'PUT', body: loginParams } }),
  };
};

export const submitAndHandleLogin =
  (loginParams: { email: string }) => async (dispatch: AppDispatch, getState: AppGetState) => {
    if (!(await validateEmail({ email: loginParams.email }))) {
      dispatch({
        type: SHOW_EMAIL_VALIDATION_ERROR,
      });
    } else {
      const response = await dispatch(loginCredentials(loginParams, getState));

      if (response.type === `${LOGIN_CREDS}.SUCCESS`) {
        removeStorageItem('email');
        dispatch(resetApp(LOGIN_RESET));
        dispatch(destroyForm(GUEST_FORM_ID));

        await dispatch(getFeatures());
        dispatch(initLogging());
        dispatch(initDeliveryPage());
      }
    }
  };
