export default {
  BASKET_PAGE_URL: 'http://www.johnlewis.com/basket',
  FLOWERS_GIFT_MESSAGE_FORM: 'https://docs.google.com/forms/d/e/1FAIpQLSee_tzA1VlPJR_3M0zdVi30dVnEnH4-RuwPCulZqJQtYoeLAQ/viewform',
  GOOGLEMAPS_API_URL: 'https://maps.googleapis.com/maps/api/js?key=',
  HOME_PAGE_URL: 'http://www.johnlewis.com',
  JL_BLADED_ITEMS_ISSUES: 'https://www.johnlewis.com/customer-services/shopping-with-us/buying-bladed-items-issues',
  JL_PARTNERSHIP_DISCOUNT_POLICY: 'https://www.partnerintranet.co.uk/itr/ls/content/4657565608771584/partnership-discount-policy',
  JL_PRIVACY_NOTICE: 'https://www.johnlewis.com/customer-services/shopping-with-us/privacy-notice',
  JL_TERMS_CONDITIONS: 'https://www.johnlewis.com/customer-services/shopping-with-us/terms-and-conditions',
  JL_VAT_RECEIPTS_AND_REFUNDS: 'https://www.johnlewis.com/customer-services/prices-and-payment/vat-receipts-and-refunds',
  KLARNA_PRIVACY_NOTICE: 'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_gb/privacy',
  KLARNA_TERMS_CONDITIONS: 'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_gb/user',
};
