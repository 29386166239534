import isEmpty from 'lodash/isEmpty';

// Types
import { DeliveryOptions } from 'types/Delivery.types';

function hasClickCollectBecomeAvailable(
  prevDeliveryOptionsAvailable: DeliveryOptions = {},
  deliveryOptions: DeliveryOptions = {},
) {
  if (isEmpty(prevDeliveryOptionsAvailable)) return false;

  const { clickCollectAvailable: prevClickCollectAvailable } = prevDeliveryOptionsAvailable;
  const { clickCollectAvailable } = deliveryOptions;

  return !!(!prevClickCollectAvailable && clickCollectAvailable);
}

export default hasClickCollectBecomeAvailable;
