import { lazy } from 'react';

// Types
import { VatRefundMessageProps as LazyComponentProps } from './VatRefundMessage.types';

// Config
import vatRefundMessageState from './VatRefundMessage.state';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: "vatRefundMessage" */ './VatRefundMessage')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'VatRefundMessage');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as VatRefundMessage, vatRefundMessageState };
