// Types
import { ProductProps } from 'types/Product.types';
import { AppClient, AppDispatch, AppGetState, RootState } from 'types/RootState.types';

// Config
import triggerAnalyticsEvent from '../analytics/analyticsAction';
import { initDeliveryPage } from '../delivery/deliveryActions';
import {
  handleRemoveItem,
  removeItemsFromBasket,
  rebatchOrderAfterBasketEdit,
} from '../edit-basket/editBasketActions';
import { isFeatureActive } from '../../reducers/config/configReducer';
import { orderFormContainsBladedItemsOnlySelector } from '../../reducers/order-form/orderFormSelector';
import {
  AGE_VERIFICATION_FAILED,
  AGE_VERIFICATION_SUCCESS,
  GET_AGE_VERIFICATION_RESULT,
  GET_AGE_VERIFICATION_SESSION,
  REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET,
  SET_AGE_VERIFICATION_CONFIRMATION_STATE,
  SET_AGE_VERIFICATION_LOADING_STATE,
  USER_AGE_CHECK,
} from '../../../constants/actionConstants';
import { FAILED_AGE_CHECK_REQUIRED } from '../../../constants/ageVerificationConstants';
import {
  URL_AGE_VERIFICATION_SESSION,
  URL_AGE_VERIFICATION_CALLBACK,
} from '../../../constants/endpointConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import featureConstants from '../../../constants/featureConstants';
import { shouldRemoveAndSaveForLater } from '../../../utils/saveForLater/saveForLaterHelp';

export const shouldShowAgeVerificationCheck = (state: RootState) =>
  !isFeatureActive(state, featureConstants.DISABLE_YOTI);

export const getAgeVerificationSession = () => ({
  type: GET_AGE_VERIFICATION_SESSION,
  request: (client: AppClient) => client({ path: URL_AGE_VERIFICATION_SESSION, config: { method: 'POST' } }),
});

export const setAgeVerificationLoadingState = (loadingState = false) => ({
  type: SET_AGE_VERIFICATION_LOADING_STATE,
  loadingState,
});

export const setAgeVerificationConfirmationState = (confirmationState = false) => ({
  type: SET_AGE_VERIFICATION_CONFIRMATION_STATE,
  confirmationState,
});

export const getItemsNeedingAgeVerification =
  (items: ProductProps[]) => items.filter(item => item.ageCheck === FAILED_AGE_CHECK_REQUIRED);

export const handleAgeVerificationCallback = (
  {
    retryTimeout = window.env.yotiRetryTimeout || 3000,
    retryAttempts = window.env.yotiRetryAttempts || 10,
    attemptNumber = 1,
  } = {},
) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const sessionId = getState().user.yotiSessionId;
  const response = await dispatch({
    type: GET_AGE_VERIFICATION_RESULT,
    request: (client: AppClient) => client({ path: URL_AGE_VERIFICATION_CALLBACK, config: { method: 'POST', body: { sessionId } } }),
  });

  if (response.error) {
    const shouldAttemptCallback = attemptNumber < retryAttempts &&
      response.error.code === errorCodeConstants.AGE_VERIFICATION_IN_PROGRESS;

    if (shouldAttemptCallback) {
      const nextAttemptNumber = attemptNumber + 1;
      setTimeout(() => {
        dispatch(handleAgeVerificationCallback({ attemptNumber: nextAttemptNumber, retryTimeout, retryAttempts }));
      }, retryTimeout);
      return;
    }

    dispatch(triggerAnalyticsEvent(AGE_VERIFICATION_FAILED));

    dispatch(getAgeVerificationSession());
  } else {
    const bladedItemsOnly = orderFormContainsBladedItemsOnlySelector(getState());

    dispatch(triggerAnalyticsEvent(
      AGE_VERIFICATION_SUCCESS,
      { ...(bladedItemsOnly && { bladedItem: 'only' }) },
    ));

    dispatch({ type: `${USER_AGE_CHECK}.SUCCESS` });
    await dispatch(initDeliveryPage({ isDeliveryPageInitialised: true }));
  }
};

export const handleAgeVerificationRemoveItem = (
  { itemsToRemove, lastItemInBasket = false }: { itemsToRemove?: ProductProps[]; lastItemInBasket?: boolean },
) => async (dispatch: AppDispatch, getState: AppGetState) => {
  if (!itemsToRemove) {
    return;
  }

  await dispatch({ type: REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET });

  const itemIds = itemsToRemove.map(item => (item.id));
  if (itemsToRemove.length === 1) {
    await dispatch(
      handleRemoveItem({
        itemId: itemIds[0],
        lastItemInBasket,
        isAgeVerification: true,
      }),
    );
  } else {
    await dispatch(removeItemsFromBasket({ itemIds, shouldInitDeliveryPage: true }));
  }

  const itemsSavedForLater = shouldRemoveAndSaveForLater({
    isSignedIn: getState().user?.isSignedIn,
  });

  await dispatch({ type: `${REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET}.SUCCESS`, itemsSavedForLater });
  await dispatch(rebatchOrderAfterBasketEdit({ shouldInitDeliveryPage: true }));
};
