import {
  formatCardNumberAmex,
  formatCardNumberDefault,
} from 'jl-design-system/form/normalizers/formatCardNumber';
import formatExpiryDate from 'jl-design-system/form/normalizers/formatExpiryDate';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {
  CARD_NUMBER,
  CARD_NAME,
  CARD_EXPIRY_DATE,
  CARD_SECURITY_CODE,
} from './cardDetails';
import { getCvvClassName } from '../helpers/creditCardInputStyles';
import supportedCreditCardTypes from '../../../constants/supportedCreditCardTypes';
import deliveryConstants from '../../../constants/deliveryConstants';
import { SecuredCardInputTooltip } from '../../../components/add-payment-details/card-details/billing-details';

export const SAVED_CARD_DETAILS_FORM_ID = 'savedCardDetailsForm';

export function canSkipCVV({ cardType, selectedDeliveryChoiceId, selectedBillingAddress, confirmedDeliveryAddress }) {
  if (cardType === supportedCreditCardTypes.BUSINESS_ACCOUNT) {
    return true;
  }

  if (cardType !== supportedCreditCardTypes.AMERICAN_EXPRESS) {
    if (selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT) {
      return true;
    }

    const addressesExist = !isEmpty(selectedBillingAddress) && !isEmpty(confirmedDeliveryAddress);
    const addressesMatch = isEqual(selectedBillingAddress, confirmedDeliveryAddress);

    if (addressesExist && addressesMatch) {
      return true;
    }
  }

  return false;
}

export default function getSavedCardDetailsFormConfig({
  cardType,
  securityCodeLength,
  securityCodeOnly,
  selectedBillingAddress,
  confirmedDeliveryAddress,
  selectedDeliveryChoiceId,
}) {
  const config = {
    id: SAVED_CARD_DETAILS_FORM_ID,
    fields: [],
  };

  if (canSkipCVV({
    cardType,
    selectedDeliveryChoiceId,
    selectedBillingAddress,
    confirmedDeliveryAddress,
  })) {
    return config;
  }

  const securityCodeField = {
    ...CARD_SECURITY_CODE,
    className: 'securityCodeField',
    inputWrapperClassName: getCvvClassName(cardType),
    minLength: securityCodeLength,
    maxLength: securityCodeLength,
    validationMessageKeys: {
      required: (cardType === supportedCreditCardTypes.AMERICAN_EXPRESS) ?
        'Please enter the 4 digits on the front of your card above the long card number' :
        'Please enter the last 3 digits found on the back of your card',
      minLength: (cardType === supportedCreditCardTypes.AMERICAN_EXPRESS) ?
        'Please enter the 4 digits on the front of your card above the long card number' :
        'Please enter the last 3 digits found on the back of your card',
    },
    labelHelperElement: <SecuredCardInputTooltip cardType={cardType} />,
  };

  if (securityCodeOnly) {
    config.fields = [
      ...config.fields,
      securityCodeField,
    ];

    return config;
  }

  config.fields = [
    ...config.fields,
    {
      ...CARD_NUMBER,
      disabled: true,
      normalize: (cardType === supportedCreditCardTypes.AMERICAN_EXPRESS) ?
        formatCardNumberAmex : formatCardNumberDefault,
    },
    {
      ...CARD_NAME,
      disabled: true,
    },
    {
      ...CARD_EXPIRY_DATE,
      disabled: true,
      normalize: formatExpiryDate,
    },
    securityCodeField,
  ];

  return config;
}
