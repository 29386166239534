import { AnyAction } from 'redux';

// Design System
import {
  LOQATE_ADDRESS_SEARCH,
  LOQATE_ADDRESS_SELECT,
} from 'jl-design-system/redux/actions/addressSearch/addressSearchActionConstants';

// Types
import { ErrorState } from 'types/RootState.types';

// Config
import {
  USER_AGE_CHECK,
  UPDATE_CUSTOMER_DOB,
  POST_ADDRESS_BOOK_CREATE,
  PUT_ADDRESS_BOOK_UPDATE,
  SET_PARTNER_DISCOUNT,
  SUBMIT_ORDER,
  APPLY_PROMO_CODE,
  ROUTER_LOCATION_CHANGE,
  DELETE_COLLECTION_POINT,
} from '../../../constants/actionConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import { getGlobalError } from '../../../utils/error/parseError';

export const INITIAL_STATE = {
  error: undefined,
};

const errorReducer = (state: ErrorState = INITIAL_STATE, action: AnyAction) => {
  const actionType = action.type || '';
  const existingErrorCode = state?.error?.code;

  if (
    actionType === ROUTER_LOCATION_CHANGE &&
    (existingErrorCode === errorCodeConstants.HANDOVER_TOKEN_BAD_SIGNATURE ||
      existingErrorCode === errorCodeConstants.HANDOVER_TOKEN_MALFORMED ||
      existingErrorCode === errorCodeConstants.HANDOVER_TOKEN_EXPIRED)
  ) {
    return state;
  }

  if (
    actionType === ROUTER_LOCATION_CHANGE ||
    actionType === `${USER_AGE_CHECK}.FAILED` ||
    actionType === `${UPDATE_CUSTOMER_DOB}.FAILED`
  ) {
    return {
      error: undefined,
    };
  }

  if (
    actionType === `${PUT_ADDRESS_BOOK_UPDATE}.FAILED` ||
    actionType === `${POST_ADDRESS_BOOK_CREATE}.FAILED` ||
    actionType === `${DELETE_COLLECTION_POINT}.FAILED` ||
    (actionType === `${SET_PARTNER_DISCOUNT}.FAILED` && action.partnerDiscountEnabled) ||
    actionType === `${APPLY_PROMO_CODE}.FAILED` ||
    actionType.includes(`${LOQATE_ADDRESS_SEARCH}.FAILED`) ||
    actionType.includes(`${LOQATE_ADDRESS_SELECT}.FAILED`) ||
    actionType.includes('PREFERENCES')
  ) {
    return state;
  }

  if (state.error) {
    return state;
  }

  const errorCode = action?.error?.code;
  const ignoreErrors = [errorCodeConstants.CLIENT_CONNECTIVITY_ERROR, errorCodeConstants.ORDERING_IN_PROGRESS];

  const ignoreAction = actionType === `${SUBMIT_ORDER}.FAILED` && ignoreErrors.includes(errorCode);

  return {
    error: actionType?.includes('.FAILED') && !ignoreAction ? getGlobalError(action) : undefined,
  };
};

export default errorReducer;
