export default function makePostcodeOptional(addressFields) {
  const postcodeFieldIndex = addressFields?.findIndex(field => field?.id === 'postcode');
  const postcodeField = addressFields[postcodeFieldIndex];

  if (postcodeField) {
    const newField = {
      ...postcodeField,
      required: false,
    };

    addressFields.splice(postcodeFieldIndex, 1, newField);
  }
}
