// Moved from deliveryDetails.jsx due to https://www.jlpit.com/jira/browse/MARV-7006
export const DELIVERY_DETAILS_FORM_ID = 'deliveryDetailsForm';

export default {
  DELIVERY: 'delivery',
  CLICK_AND_COLLECT: 'click-and-collect',
  SUPPLIER_DIRECT: 'supplierDirect',
  GREEN_VAN_FLEET: 'greenVanFleet',
  ONE_MAN: 'oneMan',
  INTERNATIONAL: 'international',
  NIGHTFREIGHT: 'NIGHTFREIGHT',
  MADE_TO_MEASURE: 'madeToMeasure',
  PREORDERABLE: 'PREORDERABLE',
  SPECIAL_ORDER: 'specialOrder',
  UNDELIVERABLE: 'undeliverable',
  STANDARD_DELIVERY: 'Standard delivery',
  DELIVERY_TYPE_STANDARD: 'STANDARD',
  DELIVERY_TYPE_CALENDAR: 'CALENDAR',
  DELIVERY_V3_FIELD: 'deliveryMethodV3',
  UNAVAILABLE: 'UNAVAILABLE',
  INSTOCK: 'INSTOCK',
  INCOMPATIBLE_SERVICE: 'incompatibleService',
  ONE_MAN_STANDARD_DELIVERY_METHOD_ID: '1',
  ONE_MAN_NAMED_DAY_DELIVERY_METHOD_ID: '5',
  ONE_MAN_MORNING_DELIVERY_METHOD_ID: '271',
  ONE_MAN_EVENING_DELIVERY_METHOD_ID: '11',
  RESTRICTED_UNTIL_PRE_ORDER_STREET_DATE: 'restrictedUntilPreorderStreetDate',
  TEMPORARY_PRE_ORDER_ERROR: 'temporaryPreOrderError',
};

export const fulfilmentTypes = {
  ONE_PERSON_STANDARD: 'OnePersonStandard',
  ONE_PERSON_NAMED_DAY_EVENING: 'OnePersonNamedDayEvening',
  ONE_PERSON_NAMED_DAY: 'OnePersonNamedDay',
  ONE_PERSON_NAMED_DAY_MORNING: 'OnePersonNamedDayMorning',
  MADE_TO_MEASURE: 'MadeToMeasure',
  TWO_PERSON: 'TwoPerson',
  TWO_PERSON_EXPRESS: 'TwoPersonExpress',
  TWO_PERSON_OUT_OF_AREA: 'TwoPersonOutOfArea',
  SUPPLIER_DIRECT_TWO_PERSON: 'SupplierDirectTwoPerson',
  SUPPLIER_DIRECT_ONE_PERSON: 'SupplierDirectOnePerson',
  COLLECT_PLUS: 'CollectPlus',
  CLICK_AND_COLLECT: 'ClickAndCollect',
};

export const undeliverableReasonConstants = {
  INCOMPATIBLE: 'incompatible',
  RESTRICTED: 'restricted',
  UNDELIVERABLE: 'undeliverable',
};

export const clearabeeConstants = {
  DESCRIPTION: 'Clearabee Sofa Collection Service',
  ADDITIONAL_INFORMATION: "Once your order has been received Clearabee will be in contact directly via email to arrange a date and time that's convenient for your collection.",
};

export const flowerConstants = {
  ADDITIONAL_INFORMATION: [
    'For next-day delivery, order by 1pm from Monday to Saturday, or by 9am on Sunday. Orders placed after these times will be dispatched the following day. This excludes bank holidays.',
    'Once you’ve placed your order, you can add a personalised message to send with your item. Find details on how to do this on the product page or order confirmation page.',
  ],
  DESCRIPTION: 'UK Delivery within 2 days',
  PRODUCT_CODE_PREFIX: '842',
};
