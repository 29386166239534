import React, { useMemo } from 'react';
import cx from 'classnames';

// Types
import { ContainerThemeProps } from './Container.types';

// Config
import { getBackgroundClass, getBorderClass, getSpacingClasses } from '../design-system/DesignSystem.constants';
import { getDataAttributes } from '../../utils/helpers/dataAttributes';

const Container = ({
  background,
  block,
  border,
  borderBottom,
  borderTop,
  children,
  className,
  flex,
  height,
  hidden,
  hideForPrint = false,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  maskPercy = false,
  maxWidth,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  relative,
  renderElse,
  renderIf,
  tag: Tag = 'div',
  testId,
  textAlign,
  width,
  ...otherProps
}: ContainerThemeProps & React.HTMLAttributes<HTMLElement>) => {
  const backgroundClass = useMemo(() => getBackgroundClass(background), [background]);

  const borderClass = useMemo(() => getBorderClass(border), [border]);
  const borderBottomClass = useMemo(() => getBorderClass(borderBottom), [borderBottom]);
  const borderTopClass = useMemo(() => getBorderClass(borderTop), [borderTop]);

  const flexClasses = useMemo(() => {
    if (!flex) return undefined;

    let classes = 'flex';
    if (typeof flex === 'object') {
      const { alignItems, flexDirection, flexItem, flexWrap, gap, justifyContent } = flex;
      classes = cx(
        'flex',
        alignItems && `items-${alignItems}`,
        flexDirection && `flex-${flexDirection}`,
        flexItem && `flex-${flexItem}`,
        flexWrap && `flex-${flexWrap}`,
        gap && `gap-${gap.replace('.', '_')}`,
        justifyContent && `justify-${justifyContent}`,
      );
    }

    return classes;
  }, [flex]);

  const marginClasses = useMemo(
    () =>
      getSpacingClasses({
        type: 'margin',
        positions: {
          all: margin,
          bottom: marginBottom,
          left: marginLeft,
          right: marginRight,
          top: marginTop,
          x: marginX,
          y: marginY,
        },
      }),
    [margin, marginBottom, marginLeft, marginRight, marginTop, marginX, marginY],
  );

  const paddingClasses = useMemo(
    () =>
      getSpacingClasses({
        type: 'padding',
        positions: {
          all: padding,
          bottom: paddingBottom,
          left: paddingLeft,
          right: paddingRight,
          top: paddingTop,
          x: paddingX,
          y: paddingY,
        },
      }),
    [padding, paddingBottom, paddingLeft, paddingRight, paddingTop, paddingX, paddingY],
  );

  const containerClass = useMemo(
    () =>
      cx(
        backgroundClass,
        borderClass,
        borderBottomClass,
        borderTopClass,
        flexClasses,
        marginClasses,
        paddingClasses,
        className,
        {
          block,
          'border-solid': borderClass || borderBottomClass || borderTopClass,
          border: borderClass,
          'border-b': borderBottomClass,
          'border-t': borderTopClass,
          hidden,
          'print-hidden': hideForPrint,
          relative,
          'text-center': textAlign === 'center',
          'text-right': textAlign === 'right',
          [`max-w-${maxWidth}`]: maxWidth,
          [`h-${height}`]: height,
          [`w-${width}`]: width,
        },
      ),
    [
      backgroundClass,
      borderClass,
      borderBottomClass,
      borderTopClass,
      flexClasses,
      marginClasses,
      paddingClasses,
      className,
      block,
      height,
      hidden,
      hideForPrint,
      maxWidth,
      relative,
      textAlign,
      width,
    ],
  );

  const getContainerTag = useMemo(
    () => (content: string | React.ReactNode) => (
      <Tag className={containerClass || undefined} {...getDataAttributes({ maskPercy, testId })} {...otherProps}>
        {content}
      </Tag>
    ),
    [Tag, containerClass, maskPercy, testId, otherProps],
  );

  if (renderIf === false || renderIf === null || renderIf === '') {
    return renderElse && getContainerTag(renderElse);
  }

  return children && getContainerTag(children);
};

export default Container;
