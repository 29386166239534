import { AnyAction } from 'redux';

// Types
import { AddressRecordProps } from 'types/Address.types';
import { ErrorProps } from 'types/Error.types';
import { CreateAccountState } from 'types/RootState.types';

// Config
import {
  SET_MYJL_MODAL_OPEN,
  SET_MYJL_MODAL_CONTENT_INDEX,
  JOIN_MY_JOHN_LEWIS,
  LOGIN_RESET,
  HAND_OVER,
  ROUTER_LOCATION_CHANGE,
  APPS_GUEST_HANDOVER,
  APPS_AUTHENTICATED_HANDOVER,
  GET_ORDER_CONFIRMATION_PAGE,
  AUTH0_REGISTRATION_CALLBACK,
  USE_FULL_SCREEN_SIGNUP_FLOW,
} from '../../../constants/actionConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import routeConstants from '../../../constants/routeConstants';
import { getNonGlobalError } from '../../../utils/error/parseError';

export function getCreateAccountError(
  uncompletedActions: string | string[],
  previousError: ErrorProps | undefined,
  isApps: boolean = false,
) {
  let errorCode = '';
  if (uncompletedActions?.includes('linkOrderToAccount') && uncompletedActions?.includes('savePersonalDetails')) {
    errorCode = isApps
      ? errorCodeConstants.CLIENT_APPS_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED
      : errorCodeConstants.CLIENT_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED;
  } else if (uncompletedActions?.includes('linkOrderToAccount')) {
    errorCode = errorCodeConstants.CLIENT_CREATE_ACCOUNT_ORDER_NOT_LINKED;
  } else if (uncompletedActions?.includes('savePersonalDetails')) {
    errorCode = isApps
      ? errorCodeConstants.CLIENT_APPS_CREATE_ACCOUNT_DETAILS_NOT_SAVED
      : errorCodeConstants.CLIENT_CREATE_ACCOUNT_DETAILS_NOT_SAVED;
  }

  return getNonGlobalError({ error: { code: errorCode } }, previousError);
}

export const INITIAL_STATE = {
  joinMyJLApiCallActive: false,
  joinMyJLApiCallComplete: false,
  joinMyJLFailed: false,
  myJLModalOpen: false,
  myJLContentIndex: 0,
  myJLMemberStatus: '',
  userAddress: undefined,
};

export default function createAccountReducer(state: CreateAccountState = INITIAL_STATE, action: AnyAction) {
  const actionType = action?.type || '';

  switch (actionType) {
    case `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`: {
      const addressBook: AddressRecordProps[] = action?.result?.customer?.addressBook;
      const contactAddress = addressBook?.find(
        (addressRecord) => addressRecord.contact && addressRecord.address?.countryCode === 'GB',
      );

      if (contactAddress) {
        return {
          ...state,
          userAddress: contactAddress,
        };
      }

      return state;
    }

    case `${AUTH0_REGISTRATION_CALLBACK}.SUCCESS`: {
      const uncompletedActions = action?.result?.uncompletedActions ?? [];
      const accountCreationSuccessError = uncompletedActions.length
        ? getCreateAccountError(uncompletedActions, state.accountCreationSuccessError, action.isApps)
        : null;

      return {
        ...state,
        accountCreationSuccessError,
      };
    }

    case `${JOIN_MY_JOHN_LEWIS}.LOADING`:
      return {
        ...state,
        joinMyJLApiCallActive: true,
      };
    case `${JOIN_MY_JOHN_LEWIS}.FAILED`:
      return {
        ...state,
        joinMyJLApiCallActive: false,
        joinMyJLFailed: true,
      };
    case `${JOIN_MY_JOHN_LEWIS}.SUCCESS`:
      return {
        ...state,
        joinMyJLApiCallActive: false,
        joinMyJLApiCallComplete: true,
        joinMyJLFailed: false,
        myJLMemberStatus: action?.result?.status,
      };

    case SET_MYJL_MODAL_OPEN:
      return {
        ...state,
        myJLModalOpen: action.open,
      };

    case LOGIN_RESET:
    case `${APPS_GUEST_HANDOVER}.SUCCESS`:
    case `${APPS_AUTHENTICATED_HANDOVER}.SUCCESS`:
    case `${HAND_OVER}.SUCCESS`: {
      return INITIAL_STATE;
    }

    case SET_MYJL_MODAL_CONTENT_INDEX:
      return {
        ...state,
        myJLContentIndex: action.index,
      };

    case ROUTER_LOCATION_CHANGE: {
      let myJLModalOpen;
      let myJLContentIndex;

      const pathname = action?.payload?.location?.pathname ?? '';

      switch (pathname) {
        case routeConstants.JOIN_MY_JL:
          myJLContentIndex = 0;
          break;

        case routeConstants.JOIN_MY_JL_EDIT:
          myJLContentIndex = 1;
          break;

        case routeConstants.JOIN_MY_JL_NEW:
          myJLContentIndex = 2;
          break;

        case routeConstants.ORDER_CONFIRMATION_JOIN_MY_JL:
          myJLModalOpen = true;
          myJLContentIndex = 0;
          break;

        case routeConstants.ORDER_CONFIRMATION_JOIN_MY_JL_EDIT:
          myJLModalOpen = true;
          myJLContentIndex = 1;
          break;

        case routeConstants.ORDER_CONFIRMATION_JOIN_MY_JL_NEW:
          myJLModalOpen = true;
          myJLContentIndex = 2;
          break;

        default:
          myJLModalOpen = false;
          myJLContentIndex = 0;
      }

      return {
        ...state,
        myJLModalOpen,
        myJLContentIndex,
      };
    }

    case USE_FULL_SCREEN_SIGNUP_FLOW: {
      return {
        ...state,
        myJlFullScreenSignUpFlowActive: action.enabled,
      };
    }

    default:
      return state;
  }
}
