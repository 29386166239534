import Input from 'jl-design-system/elements/input/Input';
import InputManageCursor from 'jl-design-system/elements/input-manage-cursor/InputManageCursor';
import { creditCardNumberCursorHelper } from 'jl-design-system/form/helpers/manageCursor';
import {
  formatCardNumberAmex,
  formatCardNumberDefault,
} from 'jl-design-system/form/normalizers/formatCardNumber';
import formatExpiryDate from 'jl-design-system/form/normalizers/formatExpiryDate';
import getRegExp, {
  numbersPattern,
  expiryDateRegex,
  lettersDotDashApostropheSpacePattern,
} from 'jl-design-system/form/regex';
import {
  numbersOnly,
  excludeSymbolsNamesDataStandards,
} from 'jl-design-system/form/normalizers/restrictInput';
import InputCreditCard from '../../../widgets/form/fields/input-credit-card';
import InputSecurityCode from '../../../widgets/form/fields/input-security-code';
import { sendNewRelicCustomEvent } from '../../logging/logging-utils';
import { getCardNumberInputClassName, getCvvClassName } from '../helpers/creditCardInputStyles';
import supportedCreditCardTypes from '../../../constants/supportedCreditCardTypes';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';

export const CARD_NUMBER = {
  component: InputCreditCard,
  name: 'cardNumber',
  id: 'cardNumber',
  type: 'tel',
  label: 'Card number',
  className: 'fieldLarge',
  required: true,
  manageCursorHelper: creditCardNumberCursorHelper,
  autoComplete: 'cc-number',
};

export const CARD_NAME = {
  component: Input,
  name: 'cardholderName',
  label: (
    <>Name on card <span>(As written on card)</span></>
  ),
  id: 'cardholderName',
  type: 'text',
  className: 'fieldLarge',
  required: true,
  maxLength: 50,
  validationMessageKeys: {
    required: 'Please enter the name on the front of your card',
    maxLength: "Sorry, you've entered a name longer than we can accept",
    regex: 'Please remove the invalid characters to continue',
  },
  regex: getRegExp(lettersDotDashApostropheSpacePattern),
  normalize: excludeSymbolsNamesDataStandards,
  autoComplete: 'cc-name',
};

export const CARD_EXPIRY_DATE = {
  component: InputManageCursor,
  name: 'expiryDate',
  label: 'Expiry date',
  id: 'expiryDate',
  type: 'tel',
  className: 'fieldSmall',
  dateFormat: 'MM-YY',
  placeholder: 'MM/YY',
  dateNotExpired: 'MM-YY',
  required: true,
  maxLength: 5,
  validationMessageKeys: {
    required: 'Please enter the expiry date',
    regex: 'Please enter a valid expiry date in format MM/YY',
    dateFormat: 'Please enter a valid expiry date in format MM/YY',
    dateNotExpired: 'This date has expired',
  },
  regex: expiryDateRegex,
  normalize: formatExpiryDate,
  autoComplete: 'cc-exp',
};

export const CARD_SECURITY_CODE = {
  component: InputSecurityCode,
  type: 'tel',
  name: 'securityCode',
  label: 'Security code',
  id: 'securityCode',
  className: 'fieldMediumInputSmall',
  required: true,
  regex: getRegExp(numbersPattern),
  normalize: numbersOnly,
  autoComplete: 'cc-csc',
};

export const CARD_DETAILS_FORM_ID = 'cardDetailsForm';
export const PREPAID_CARD_DETAILS_FORM_ID = 'prepaidCardDetailsForm';
const instruction = (cardType, prepaidCard) => {
  if (cardType === supportedCreditCardTypes.AMERICAN_EXPRESS) {
    return 'This is the 4 digits on the front of the card above the long card number';
  }

  if (!prepaidCard) {
    return 'This is the last 3 digits on the back of your card';
  }

  return '';
};

export const validateBinRange = (number = '') => {
  const isOldPartnershipCard = number?.replace(' ', '')?.startsWith(paymentTypeConstants.OLD_PARTNERSHIP_BIN_RANGE);

  if (isOldPartnershipCard) {
    sendNewRelicCustomEvent('unsupportedBinRange', {
      binRange: paymentTypeConstants.OLD_PARTNERSHIP_BIN_RANGE,
    });

    return 'This HSBC Partnership Card has expired and can no longer be used';
  }

  return undefined;
};

export function getCardDetailsFormConfig({
  cardType,
  cardMaxLength,
  cardMinLength,
  prepaidCard,
  securityCodeLength,
}) {
  const config = {
    id: prepaidCard ? PREPAID_CARD_DETAILS_FORM_ID : CARD_DETAILS_FORM_ID,
    fields: [
      {
        ...CARD_NUMBER,
        inputWrapperClassName: getCardNumberInputClassName(cardType),
        normalize: (cardType === supportedCreditCardTypes.AMERICAN_EXPRESS) ?
          formatCardNumberAmex : formatCardNumberDefault,
        minLength: cardMinLength,
        luhnNumberCheck: cardMinLength,
        maxLength: (cardType === supportedCreditCardTypes.AMERICAN_EXPRESS) ? cardMaxLength + 2 : cardMaxLength + 4,
        validationMessageKeys: {
          required: 'Please enter the card number on the front of your card',
          minLength: (cardType === supportedCreditCardTypes.AMERICAN_EXPRESS) ?
            'Please enter the 15 digit card number on the front of your card' :
            "The card number you've entered is too short",
          luhnNumberCheck: "We don't recognise your card number. Please check and try again",
        },
        validationFnc: validateBinRange,
      },
      ...(prepaidCard ? [] : [CARD_NAME]),
      {
        ...CARD_EXPIRY_DATE,
        normalize: formatExpiryDate,
      },
    ],
  };

  if (cardType === supportedCreditCardTypes.BUSINESS_ACCOUNT) {
    return config;
  }

  config.fields = [
    ...config.fields,
    {
      ...CARD_SECURITY_CODE,
      inputWrapperClassName: getCvvClassName(cardType),
      minLength: securityCodeLength,
      maxLength: securityCodeLength,
      validationMessageKeys: {
        required: (cardType === supportedCreditCardTypes.AMERICAN_EXPRESS) ?
          'Please enter the 4 digits on the front of your card above the long card number' :
          'Please enter the last 3 digits found on the back of your card',
        minLength: (cardType === supportedCreditCardTypes.AMERICAN_EXPRESS) ?
          'Please enter the 4 digits on the front of your card above the long card number' :
          'Please enter the last 3 digits found on the back of your card',
      },
      instruction: instruction(cardType, prepaidCard),
    },
  ];

  return config;
}
