import React from 'react';
import { useDispatch } from 'react-redux';

// Design System
import Checkbox from 'jl-design-system/components/checkbox/Checkbox';

// Types
import { AppDispatch } from 'types/RootState.types';
import { GiftReceiptProps } from './GiftReceipt.types';

// Config
import { setGiftReceiptSelected } from '../../redux/actions/app/appActions';
import { giftReceiptSelected } from '../../redux/actions/delivery/deliveryActions';

const GiftReceipt = ({
  checked = false,
  className,
  classNameOverride,
  focusOnLabel,
  giftFormId = '',
  id,
  label,
  labelClassName,
  name,
  value,
}: GiftReceiptProps) => {
  const dispatch: AppDispatch = useDispatch();

  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = evt.target?.checked;

    dispatch(giftReceiptSelected(isSelected));
    dispatch(setGiftReceiptSelected({ isSelected, giftFormId }));
  };

  return (
    <div className={classNameOverride}>
      <Checkbox
        checked={checked}
        className={className}
        focusOnLabel={focusOnLabel}
        id={id}
        label={label}
        labelClassName={labelClassName}
        name={name}
        onChange={onChangeHandler}
        value={value}
      />
    </div>
  );
};

export default GiftReceipt;
