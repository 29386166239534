// Types
import { RootState } from 'types/RootState.types';

export const getActiveModalMapMarkerSelector = (state: RootState) => state.clickAndCollect?.activeModalMapMarker;
export const getActiveViewTypeSelector = (state: RootState) => state.clickAndCollect?.activeViewType ?? '';
export const getCollectorDetailsSelector = (state: RootState) => state.clickAndCollect?.collectorDetails;
export const getCollectionDateTimeStringSelector = (state: RootState) =>
  state.clickAndCollect?.collectionDateTimeString;
export const getConfirmedCollectionPointSelector = (state: RootState) =>
  state.clickAndCollect?.confirmedCollectionPoint;
export const getCutOffTimeSelector = (state: RootState) => state.clickAndCollect?.cutOffTimeForSelectedStore;
export const getCollectionPointSearchActiveSelector = (state: RootState) =>
  state.clickAndCollect?.collectionPointSearchActive || false;
export const getCollectionPointSearchCompleteSelector = (state: RootState) =>
  state.clickAndCollect?.collectionPointSearchComplete || false;
export const getGeoLocationSearchActiveSelector = (state: RootState) =>
  state.clickAndCollect?.geoLocationSearchActive || false;
export const getGoogleMapsScriptLoadedSelector = (state: RootState) =>
  state.clickAndCollect?.googleMapsScriptLoaded || false;
export const getGoogleMapsScriptLoadingSelector = (state: RootState) =>
  state.clickAndCollect?.googleMapsScriptLoading || false;
export const getGiftMessageLinesSelector = (state: RootState) => state.clickAndCollect?.giftMessageLines;
export const getClickAndCollectProceedingToPaymentSelector = (state: RootState) =>
  state.clickAndCollect?.proceedingToPayment || false;
export const getSelectedCollectionPointSelector = (state: RootState) => state.clickAndCollect?.selectedCollectionPoint;
export const getSelectedCollectionPointPostcodeSelector = (state: RootState) =>
  state.clickAndCollect?.selectedCollectionPoint?.address?.postcode;
export const getShowCutOffPassedModalSelector = (state: RootState) =>
  state.clickAndCollect?.showCutOffPassedModal || false;
export const getStoreDetailsModalOpenSelector = (state: RootState) =>
  state.clickAndCollect?.storeDetailsModalOpen || false;
export const getSuggestedNewCollectionDateSelector = (state: RootState) =>
  state.clickAndCollect?.suggestedNewCollectionDate;
export const getCollectionPointSearchResultsSelector = (state: RootState) =>
  state.clickAndCollect?.collectionPointSearchResults;
export const getCollectionPointSearchTermSelector = (state: RootState) =>
  state.clickAndCollect?.collectionPointSearchTerm;
export const getFindCollectionPointErrorSelector = (state: RootState) =>
  state.clickAndCollect?.findCollectionPointError;
export const getModalShowSelectShopButtonSelector = (state: RootState) =>
  state.clickAndCollect?.modalShowSelectShopButton || false;
export const getTotalCollectionPointsSelector = (state: RootState) => state.clickAndCollect?.totalCollectionPoints || 0;
export const getCollectionPointServiceDownSelector = (state: RootState) =>
  state.clickAndCollect?.collectionPointServiceDown || false;
export const getCollectionPointDetailsCallActiveSelector = (state: RootState) =>
  state.clickAndCollect?.getCollectionPointDetailsCallActive || false;
export const getCollectionPointDatesApiCallActiveSelector = (state: RootState) =>
  state.clickAndCollect?.getCollectionPointDatesApiCallActive || false;
export const getSavedCollectionPointsApiCallLoadingSelector = (state: RootState) =>
  state.clickAndCollect?.getSavedCollectionPointsApiCallLoading || false;
export const getCollectionPointsOwnersSelector = (state: RootState) => state.clickAndCollect?.collectionPointsOwners;
export const getIsFreeCollectionSelector = (state: RootState) => state.clickAndCollect?.isFreeCollection ?? true;
export const getClickAndCollectActiveMapMarkerSelector = (state: RootState) => state.clickAndCollect?.activeMapMarker;
export const getClickAndCollectCountdownVisibleSelector = (state: RootState) =>
  state.clickAndCollect?.countdownVisible || false;
export const getClickAndCollectCutOffTimeForAllStoresSelector = (state: RootState) =>
  state.clickAndCollect?.cutOffTimeForAllStores;
export const getClickAndCollectEarliestCollectionDateTimeSelector = (state: RootState) =>
  state.clickAndCollect?.earliestCollectionDateTime;
export const getClickAndCollectSelectingCollectionPointIdSelector = (state: RootState) =>
  state.clickAndCollect?.selectingCollectionPointId;
export const getClickAndCollectStoreToPromoteSelector = (state: RootState) => state.clickAndCollect?.storeToPromote;
export const getDeleteCollectionPointApiCallActiveSelector = (state: RootState) =>
  state.clickAndCollect?.deleteCollectionPointApiCallActive || false;
export const getShowCollectionPointDeleteSuccessMessageSelector = (state: RootState) =>
  state.clickAndCollect?.showCollectionPointDeleteSuccessMessage || false;
export const getShowCollectionPointDeleteFailedMessageSelector = (state: RootState) =>
  state.clickAndCollect?.showCollectionPointDeleteFailedMessage || false;
export const getRemoveCollectionPointOverlayVisibleSelector = (state: RootState) =>
  state.clickAndCollect?.removeCollectionPointOverlayVisible || false;
export const getCollectionPointIdToBeRemovedSelector = (state: RootState) =>
  state.clickAndCollect?.collectionPointIdToBeRemoved;
export const getSelectingCollectionPointIdSelector = (state: RootState) =>
  state.clickAndCollect?.selectingCollectionPointId;
export const getSavedCollectionViewSelector = (state: RootState) => state.clickAndCollect?.savedCollectionView;
export const getHideMapInStoreDetailsModalSelector = (state: RootState) =>
  state.clickAndCollect?.hideMapInStoreDetailsModal;
export const getProceedingToPaymentSelector = (state: RootState) => state.clickAndCollect?.proceedingToPayment || false;
