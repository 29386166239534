import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import { isFeatureActive } from '../config/configReducer';
import featureConstants from '../../../constants/featureConstants';
import { orderContainsMixedDeliveryTypes } from '../../../utils/collection/collectionHelp';
import { getPersonalisedItems } from '../../../utils/personalisation/personalisationHelp';

export const getDeliveryBatchesProcessingSelector = (state: RootState) => state.delivery?.batchesProcessing || false;
export const getDeliveryDeliveryContainsUndeliverableServices = (state: RootState) =>
  state.delivery?.deliveryContainsUndeliverableServices || false;
export const getServicesThatNeedRemovingSelector = (state: RootState) => state.delivery?.servicesThatNeedRemoving;
export const getDeliveryPutDeliveryAddressErrorSelector = (state: RootState) => state.delivery?.putDeliveryAddressError;
export const getConfirmedDeliveryAddressSelector = (state: RootState) => state.delivery?.confirmedDeliveryAddress;
export const getConfirmedDeliveryAddressPostcodeSelector = (state: RootState) =>
  state.delivery.confirmedDeliveryAddress?.address?.postcode;
export const getCreateAddressAPICallActiveSelector = (state: RootState) =>
  state.delivery?.createAddressAPICallActive || false;
export const getDeliveriesSelector = (state: RootState) => state.delivery?.deliveries;
export const getDeliveryOptionsSelector = (state: RootState) => state.delivery?.deliveryOptions;
export const getDeliveryOptionsCollectionPlusAvailableSelector = (state: RootState) =>
  state.delivery?.deliveryOptions?.collectPlusAvailable || false;
export const getEditBasketModalBaseRoutePathSelector = (state: RootState) =>
  state.delivery?.editBasketModalBaseRoutePath || '';
export const getBatchesDeliveryMethodsApiCallActiveSelector = (state: RootState) =>
  state.delivery?.getBatchesDeliveryMethodsApiCallActive || false;
export const getItemsNeedingAgeVerificationSelector = (state: RootState) => state.delivery?.itemsNeedingAgeVerification;
export const getPostAgeVerificationApiCallsActiveSelector = (state: RootState) =>
  state.delivery?.postAgeVerificationApiCallsActive || false;
export const getDeliveryProceedingToPaymentSelector = (state: RootState) =>
  state.delivery?.proceedingToPayment || false;
export const getPutDeliveryAddressApiCallActiveSelector = (state: RootState) =>
  state.delivery?.putDeliveryAddressApiCallActive || false;
export const getSelectedDeliveryChoiceIdSelector = (state: RootState) => state.delivery?.selectedDeliveryChoiceId || '';
export const getShowAmendQuantityNotificationSelector = (state: RootState) =>
  state.delivery?.showAmendQuantityNotification || false;
export const getShowClickCollectNowAvailableMessageSelector = (state: RootState) =>
  state.delivery?.showClickCollectNowAvailableMessage || false;
export const getShowEditBasketModalSelector = (state: RootState) => state.delivery?.showEditBasketModal || false;
export const getShowSavedCollectionPointsModalSelector = (state: RootState) =>
  state.delivery?.showSavedCollectionPointsModal || false;
export const getShowCollectionHelpSelector = createSelector([getDeliveriesSelector], (deliveries) =>
  orderContainsMixedDeliveryTypes(deliveries),
);
export const getDeliveryGiftMessageValidationFailedSelector = (state: RootState) =>
  state.delivery?.giftMessageValidationFailed || false;
export const getIsSingleBatchFlowers = (state: RootState) =>
  state.delivery?.deliveriesWithFlowers?.length === 1 && state.delivery?.deliveries?.length === 1;
export const getIsMultiBatchFlowers = (state: RootState) =>
  (state.delivery?.deliveriesWithFlowers?.length || 0) >= 1 && (state.delivery?.deliveries?.length || 0) > 1;
export const getIsSingleBatchClearabee = (state: RootState) =>
  state.delivery?.deliveriesWithClearabee?.length === 1 && state.delivery?.deliveries?.length === 1;
export const getIsMultiBatchClearabee = (state: RootState) =>
  (state.delivery?.deliveriesWithClearabee?.length || 0) >= 1 && (state.delivery?.deliveries?.length || 0) > 1;
export const getDeliveriesWithClearabee = (state: RootState) => state.delivery?.deliveriesWithClearabee;
export const getDeliveryPageApiCallCompleteSelector = (state: RootState) =>
  state.delivery?.getDeliveryPageApiCallComplete;
export const getShowAgeVerificationSaveForLaterToastSelector = (state: RootState) =>
  state.delivery?.showAgeVerificationSaveForLaterToast;
export const getIsDeliveryPageInitialisedSelector = (state: RootState) =>
  state.delivery?.isDeliveryPageInitialised || false;
export const getIsFlowersPropositionActiveSelector = (state: RootState) =>
  isFeatureActive(state, featureConstants.FLOWERS_PROPOSITION_ENABLED);
export const getShowPersonalisationMessageSelector = createSelector(
  [getIsFlowersPropositionActiveSelector, getDeliveriesSelector],
  (flowersIsActive, deliveries = []) => getPersonalisedItems({ deliveries, flowersIsActive })?.length > 0,
);
export const getUndeliverableItemsInBasketSelector = (state: RootState) => state.delivery?.undeliverableItemsInBasket;
