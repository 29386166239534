import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { goBack, push } from 'connected-react-router';
import cx from 'classnames';
// jl-design-system
import { Modal } from 'jl-design-system/components/modal/Modal';
import { SecondaryButton, UnderlinedButton } from 'jl-design-system/elements/button/Button';
// redux
import { connect } from 'react-redux';
import { destroyForm } from 'jl-design-system/redux/actions/form/formActions';
import { resetAddressSearch } from 'jl-design-system/redux/actions/address/addressActions';
import { openSessionEndedModal } from '../../redux/actions/app/appActions';
import {
  onEnterManualAddress,
  onLoqateNetworkTimeoutCallback,
  onLoqateSearch,
  triggerLoqateTimeoutEnterAddressManuallyAnalytics,
} from '../../redux/actions/address/addressActions';
import { isInternational } from '../../redux/reducers/payment/orderFormReducer';
import { isApps } from '../../redux/reducers/app/appReducer';
import getLoqateMocks from '../../non-prod-utils/utils/loqate/getLoqateMocks';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';
import {
  joinMyJohnLewis,
  setMyJLModalOpen,
  setMyJLModalContentIndex,
  enterNewDetails,
  toggleFullScreenSignUp,
} from '../../redux/actions/create-account/createAccountActions';
import {
  triggerAnalyticsEvent,
} from '../../redux/actions/analytics/analyticsAction';
// config
import featureConstants from '../../constants/featureConstants';
import routeConstants from '../../constants/routeConstants';
import { MYJL_ADDRESS, ANALYTICS_MY_JL_JOIN_MODAL_OPEN } from '../../constants/actionConstants';
import {
  getMyJohnLewisAddressFormSimpleConfig,
  getMyJohnLewisAddressFormEditConfig,
  getMyJohnLewisAddressFormNewConfig,
  MY_JOHN_LEWIS_ADDRESS_FORM_SIMPLE_ID,
  MY_JOHN_LEWIS_ADDRESS_FORM_EDIT_ID,
  MY_JOHN_LEWIS_ADDRESS_FORM_NEW_ID,
} from '../../utils/form/configs/myJohnLewis';
import shouldShowFullScreenSignUp from '../../utils/myjl/fullScreenSignUp';
import userConstants from '../../constants/userConstants';
// components
import BodyText from '../body-text';
import Container from '../container';
import Heading from '../heading';
import MyJohnLewisDetails from './MyJohnLewisDetails';
import MyAccountLink from '../my-account-link';
// styles
import styles from './my-john-lewis.scss';


export const formConfigMap = [
  MY_JOHN_LEWIS_ADDRESS_FORM_SIMPLE_ID,
  MY_JOHN_LEWIS_ADDRESS_FORM_EDIT_ID,
  MY_JOHN_LEWIS_ADDRESS_FORM_NEW_ID,
];

export class MyJohnLewis extends PureComponent {

  static propTypes = {
    allowMyJlSignUps: PropTypes.bool,
    className: PropTypes.string,
    destroyForm: PropTypes.func,
    enterNewDetails: PropTypes.func,
    goBack: PropTypes.func,
    isApps: PropTypes.bool,
    isInternationalAddress: PropTypes.bool,
    joinMyJLSuccess: PropTypes.bool,
    joinMyJohnLewis: PropTypes.func,
    loqateApiKey: PropTypes.string,
    myJLMemberStatus: PropTypes.string,
    myJLModalContentIndex: PropTypes.number,
    onEnterManualAddress: PropTypes.func,
    onLoqateNetworkTimeoutCallback: PropTypes.func,
    onLoqateSearch: PropTypes.func,
    openSessionEndedModal: PropTypes.func,
    push: PropTypes.func,
    resetAddressSearch: PropTypes.func,
    sessionExpiredAnnounced: PropTypes.bool,
    sessionId: PropTypes.string,
    setMyJLModalContentIndex: PropTypes.func,
    setMyJLModalOpen: PropTypes.func,
    showFullScreenSignup: PropTypes.bool,
    showMyJLModal: PropTypes.bool,
    sidebarView: PropTypes.bool,
    toggleFullScreenSignUp: PropTypes.func,
    triggerAnalyticsEvent: PropTypes.func,
    triggerLoqateTimeoutEnterAddressManuallyAnalytics: PropTypes.func,
    userAddress: PropTypes.shape({
      address: PropTypes.shape({}),
      addressee: PropTypes.shape({}),
      phoneNumber: PropTypes.string,
    }),
  };

  static defaultProps = {
    allowMyJlSignUps: false,
    className: '',
    destroyForm: window.defaultFunc,
    enterNewDetails: window.defaultFunc,
    goBack: window.defaultFunc,
    isApps: false,
    isInternationalAddress: false,
    joinMyJLSuccess: false,
    joinMyJohnLewis: window.defaultFunc,
    loqateApiKey: '',
    myJLMemberStatus: '',
    myJLModalContentIndex: 0,
    onEnterManualAddress: window.defaultFunc,
    onLoqateNetworkTimeoutCallback: window.defaultFunc,
    onLoqateSearch: window.defaultFunc,
    openSessionEndedModal: window.defaultFunc,
    push: window.defaultFunc,
    resetAddressSearch: window.defaultFunc,
    sessionExpiredAnnounced: false,
    sessionId: undefined,
    setMyJLModalContentIndex: window.defaultFunc,
    setMyJLModalOpen: window.defaultFunc,
    showFullScreenSignup: false,
    showMyJLModal: false,
    sidebarView: false,
    toggleFullScreenSignUp: window.defaultFunc,
    triggerAnalyticsEvent: window.defaultFunc,
    triggerLoqateTimeoutEnterAddressManuallyAnalytics: window.defaultFunc,
    userAddress: {
      addressee: {},
      address: {},
      phoneNumber: '',
    },
  };

  componentDidUpdate(prevProps) {
    const {
      myJLModalContentIndex,
      destroyForm,
      resetAddressSearch,
      joinMyJLSuccess,
      push,
    } = this.props;

    if (prevProps.myJLModalContentIndex === 2 && myJLModalContentIndex === 1) {
      resetAddressSearch({ addressId: MYJL_ADDRESS });
      destroyForm(formConfigMap[prevProps.myJLModalContentIndex]);
    }

    if (prevProps.myJLModalContentIndex === 1 && myJLModalContentIndex === 0) {
      destroyForm(formConfigMap[prevProps.myJLModalContentIndex]);
    }

    if (!prevProps.joinMyJLSuccess && joinMyJLSuccess) {
      push(routeConstants.ORDER_CONFIRMATION);
    }
  }

  onJoinMyJLClick = () => {
    const {
      isInternationalAddress,
      triggerAnalyticsEvent,
      push,
      openSessionEndedModal,
      sessionExpiredAnnounced,
    } = this.props;

    if (sessionExpiredAnnounced) {
      openSessionEndedModal();
    } else {
      push(routeConstants.ORDER_CONFIRMATION_JOIN_MY_JL);

      triggerAnalyticsEvent(ANALYTICS_MY_JL_JOIN_MODAL_OPEN, {
        international: isInternationalAddress,
      });
    }
  };

  onMyJLModalClose = () => {
    const {
      push,
    } = this.props;

    push(routeConstants.ORDER_CONFIRMATION);
  };

  onMyJlAddressFormSubmit = (formValues) => {
    const {
      joinMyJohnLewis,
      showFullScreenSignup,
    } = this.props;

    return joinMyJohnLewis(formValues, showFullScreenSignup);
  };

  onModalBackClick = () => {
    const { myJLModalContentIndex, goBack } = this.props;

    goBack();

    const newIndex = myJLModalContentIndex - 1;
    this.updateModalContent(newIndex);
  };

  onMyJlEditAddress = () => {
    this.updateModalContent(1);
  };

  updateModalContent(index) {
    const { setMyJLModalContentIndex } = this.props;
    setMyJLModalContentIndex(index);
  }

  renderSuccessMessage(testAttr = 'myjl-success-message') {
    const {
      myJLMemberStatus,
      showMyJLModal,
      isApps,
      sidebarView,
    } = this.props;

    return (
      <Container
        className={cx(styles.successMessage, {
          [styles.successMessageSidebar]: sidebarView,
        })}
        testId={testAttr}
      >
        {!showMyJLModal && (
          <Heading
            label="my John Lewis"
            marginBottom="2"
            tag="h2"
            type="s"
          />
        )}
        <Container
          background={!showMyJLModal ? 'white' : ''}
          paddingX={!showMyJLModal ? '4' : ''}
          paddingY={!showMyJLModal ? '2' : ''}
        >
          {
            myJLMemberStatus === userConstants.MY_JL_JOINED &&
            <>
              <Heading
                label="You’re in!"
                marginBottom="1"
                tag="h3"
              />
              <p>
                Head to <MyAccountLink isApps={isApps} /> to view your rewards
                and start enjoying the benefits of being a My John Lewis member.
              </p>

              {
                !isApps &&
                <div className={styles.downloadApp}>
                  <Heading
                    label="Download the app"
                    marginBottom="1"
                    tag="h4"
                    type="xs"
                  />
                  <p>
                    It’s the quickest way to see your rewards, access your card,
                    and has even more exclusive benefits.
                  </p>

                  <div className={styles.qrCode}>
                    <p>Just scan this QR code from your device to download the app for iOS or Android now.</p>
                  </div>

                  <div className={styles.appStoreLinks}>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.johnlewis.android&hl=en_GB&gl=US"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Get it on Google Play
                    </a>
                    <a
                      href="https://apps.apple.com/gb/app/john-lewis-partners/id486502369"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Download on the App Store
                    </a>
                  </div>
                </div>
              }
            </>
          }
          {
            myJLMemberStatus === userConstants.MY_JL_ACCEPTED &&
            <p>
              Please allow up to 24 hours for your account to be updated.
              Your membership card will be stored online and can be accessed when signing in to
              the John Lewis & Partners website or app.
              No need to carry a plastic card.
            </p>
          }
        </Container>
      </Container>
    );
  }

  renderEnterNewDetails = () => {
    const {
      enterNewDetails,
    } = this.props;

    return (
      <>
        Please update your details or alternatively{' '}
        <UnderlinedButton
          data-testid="myjl-enter-new-address"
          onClick={enterNewDetails}
        >
          enter new details
        </UnderlinedButton> to sign up for my John Lewis.
      </>
    );
  };

  renderModalContent(ukOnlyMessage) {
    const {
      userAddress,
      myJLModalContentIndex,
      joinMyJLSuccess,
      loqateApiKey,
      onLoqateNetworkTimeoutCallback,
      onLoqateSearch,
      onEnterManualAddress,
      sessionId,
      showFullScreenSignup,
    } = this.props;

    const { address: { countryCode } = {} } = userAddress;
    const isInternationalAddress = countryCode !== 'GB';

    if (joinMyJLSuccess) {
      return this.renderSuccessMessage('myjl-success-modal');
    }

    const newFormParams = {
      key: MY_JOHN_LEWIS_ADDRESS_FORM_NEW_ID,
      formId: MY_JOHN_LEWIS_ADDRESS_FORM_NEW_ID,
      getFormConfig: getMyJohnLewisAddressFormNewConfig,
      onMyJlAddressFormSubmit: this.onMyJlAddressFormSubmit,
      loqateApiKey,
      getLoqateMocks,
      onLoqateTimeoutEnterAddressManuallyCallback: triggerLoqateTimeoutEnterAddressManuallyAnalytics,
      onLoqateNetworkTimeoutCallback,
      onLoqateSearch,
      onEnterManualAddress,
      showFullScreenSignup,
    };

    if (isInternationalAddress) {
      return (
        <MyJohnLewisDetails
          title={`Please enter your details to sign up for my John Lewis and enjoy our exclusive rewards. ${ukOnlyMessage}`}
          {...newFormParams}
        />
      );
    }

    if (userAddress) {
      switch (myJLModalContentIndex) {
        case 0:
          return (
            <MyJohnLewisDetails
              key={MY_JOHN_LEWIS_ADDRESS_FORM_SIMPLE_ID}
              disablePostcodeSearch
              formId={MY_JOHN_LEWIS_ADDRESS_FORM_SIMPLE_ID}
              getFormConfig={getMyJohnLewisAddressFormSimpleConfig}
              getLoqateMocks={getLoqateMocks}
              loqateApiKey={loqateApiKey}
              onEnterManualAddress={onEnterManualAddress}
              onLoqateNetworkTimeoutCallback={onLoqateNetworkTimeoutCallback}
              onLoqateSearch={onLoqateSearch}
              onLoqateTimeoutEnterAddressManuallyCallback={triggerLoqateTimeoutEnterAddressManuallyAnalytics}
              onMyJlAddressFormSubmit={this.onMyJlAddressFormSubmit}
              onMyJlEditAddress={this.onMyJlEditAddress}
              sessionId={sessionId}
              showFullScreenSignup={showFullScreenSignup}
              simpleForm
              title="Please confirm your details to sign up for my John Lewis and enjoy our exclusive rewards."
              userAddress={userAddress}
            />
          );
        case 1:
          return (
            <MyJohnLewisDetails
              key={MY_JOHN_LEWIS_ADDRESS_FORM_EDIT_ID}
              disablePostcodeSearch
              formId={MY_JOHN_LEWIS_ADDRESS_FORM_EDIT_ID}
              getFormConfig={getMyJohnLewisAddressFormEditConfig}
              getLoqateMocks={getLoqateMocks}
              loqateApiKey={loqateApiKey}
              onEnterManualAddress={onEnterManualAddress}
              onLoqateNetworkTimeoutCallback={onLoqateNetworkTimeoutCallback}
              onLoqateSearch={onLoqateSearch}
              onLoqateTimeoutEnterAddressManuallyCallback={triggerLoqateTimeoutEnterAddressManuallyAnalytics}
              onMyJlAddressFormSubmit={this.onMyJlAddressFormSubmit}
              sessionId={sessionId}
              showFullScreenSignup={showFullScreenSignup}
              title={this.renderEnterNewDetails()}
              userAddress={userAddress}
            />
          );
        default:

      }
    }

    return (
      <MyJohnLewisDetails
        title="Please enter your details to sign up for my John Lewis."
        {...newFormParams}
      />
    );
  }

  render() {
    const {
      myJLModalContentIndex,
      joinMyJLSuccess,
      isInternationalAddress,
      className,
      showMyJLModal,
      allowMyJlSignUps,
      showFullScreenSignup,
      toggleFullScreenSignUp,
    } = this.props;

    if (!allowMyJlSignUps) {
      return null;
    }

    const showBackButton = myJLModalContentIndex > 0;
    const ukOnlyMessage = isInternationalAddress ? 'Membership is only available to UK residents.' : '';

    const containerStyles = cx(styles.container, {
      [className]: !!className,
    });

    if (showFullScreenSignup) {
      return (
        <>
          <Container background="white" className={styles.logoContainer} marginBottom="2">
            <div className={styles.logo} />
          </Container>
          { this.renderModalContent(ukOnlyMessage) }
          <UnderlinedButton
            className={styles.backToOrder}
            onClick={() => toggleFullScreenSignUp(false)}
          >
            Go back to your order
          </UnderlinedButton>
        </>
      );
    }

    return (
      <Container className={containerStyles} hideForPrint>

        {!joinMyJLSuccess && (
          <Container className={styles.prompt} padding="3" testId="myjl-prompt-message">
            <Heading
              className={styles.textHeading}
              label="Join My John Lewis"
              marginBottom="2"
              tag="h2"
              type="s"
            />
            <Heading className={styles.rewardingHeading} tag="h4">Rewarding little moments</Heading>
            <BodyText className={styles.promptContent} marginBottom="2">
              Get a £5 e-gift card when you spend your first £50, plus personalised offers,
              exclusive early access to new launches, members-only events and more
            </BodyText>
            <SecondaryButton
              className={styles.button}
              data-testid="myjl-join-link"
              onClick={this.onJoinMyJLClick}
            >
              Join now
            </SecondaryButton>
          </Container>
        )}

        {
          joinMyJLSuccess && this.renderSuccessMessage()
        }

        {showMyJLModal && (
          <Modal
            backButton={showBackButton}
            className={styles.modal}
            footer={false}
            height="hug"
            isOpen
            onBackButtonClick={this.onModalBackClick}
            onClose={this.onMyJLModalClose}
            title="Add My John Lewis details"
          >
            { this.renderModalContent(ukOnlyMessage) }
          </Modal>
        )}
      </Container>
    );
  }

}

export function mapStateToProps(state) {
  return {
    isInternationalAddress: isInternational(state),
    userAddress: state.createAccount.userAddress,
    showMyJLModal: state.createAccount.myJLModalOpen && !shouldShowFullScreenSignUp(state),
    myJLModalContentIndex: state.createAccount.myJLContentIndex,
    myJLMemberStatus: state.createAccount.myJLMemberStatus,
    isApps: isApps(state),
    loqateApiKey: state.config?.frontendKeys?.loqateApiKey,
    allowMyJlSignUps: isFeatureActive(state, featureConstants.ALLOW_MY_JL_SIGN_UPS),
    sessionExpiredAnnounced: state.app.sessionExpiredAnnounced,
    sessionId: state.bff.sessionId,
    showFullScreenSignup: shouldShowFullScreenSignUp(state),
  };
}

export function mapDispatchToProps() {
  return {
    joinMyJohnLewis,
    setMyJLModalOpen,
    setMyJLModalContentIndex,
    destroyForm,
    triggerAnalyticsEvent,
    resetAddressSearch,
    goBack,
    push,
    triggerLoqateTimeoutEnterAddressManuallyAnalytics,
    openSessionEndedModal,
    onLoqateNetworkTimeoutCallback,
    onLoqateSearch,
    onEnterManualAddress,
    enterNewDetails,
    toggleFullScreenSignUp,
  };
}

export default connect(state => mapStateToProps(state), mapDispatchToProps())(MyJohnLewis);
