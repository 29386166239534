import { createSelector } from 'reselect';

// Config
import { getPricePromiseFeatureActiveSelector } from '../price-promise/PricePromise.state';
import {
  getConfirmationIsClickCollectOrderSelector,
  getConfirmationIsMultiBatchSelector,
  getConfirmationOrderAmountsSelector,
  getConfirmationOrderDeliveriesSelector,
  getConfirmationPromoCodeAppliedSelector,
} from '../../redux/reducers/confirmation/orderConfirmationSelector';
import {
  getApplyRewardsApiCallActiveSelector,
  getOrderFormAmountsSelector,
  getOrderFormIsClickCollectOrderSelector,
  getOrderFormIsGiftReceiptOrderSelector,
  getOrderFormPromoCodeAppliedSelector,
} from '../../redux/reducers/order-form/orderFormSelector';

const orderTotalsOrderFormState = createSelector(
  [
    getOrderFormAmountsSelector,
    getOrderFormIsGiftReceiptOrderSelector,
    getOrderFormIsClickCollectOrderSelector,
    getOrderFormPromoCodeAppliedSelector,
    getApplyRewardsApiCallActiveSelector,
    getPricePromiseFeatureActiveSelector,
  ],
  (
    amounts,
    isGiftReceiptOrder,
    isClickCollectOrder,
    promotionalCode,
    applyRewardsApiCallActive,
    pricePromiseFeatureActive,
  ) => ({
    amounts,
    applyRewardsApiCallActive,
    isGiftReceiptOrder,
    isClickCollectOrder,
    pricePromiseFeatureActive,
    promotionalCode,
  }),
);

const orderTotalsConfirmationPagePropsState = createSelector(
  [
    getConfirmationOrderAmountsSelector,
    getConfirmationIsMultiBatchSelector,
    getConfirmationOrderDeliveriesSelector,
    getConfirmationIsClickCollectOrderSelector,
    getConfirmationPromoCodeAppliedSelector,
    getApplyRewardsApiCallActiveSelector,
    getPricePromiseFeatureActiveSelector,
  ],
  (
    amounts,
    isMultiBatch,
    deliveries,
    isClickCollectOrder,
    promotionalCode,
    applyRewardsApiCallActive,
    pricePromiseFeatureActive,
  ) => ({
    amounts,
    applyRewardsApiCallActive,
    isGiftReceiptOrder: !isMultiBatch && deliveries?.some(delivery => delivery.isGiftReceiptOrder),
    isClickCollectOrder,
    pricePromiseFeatureActive,
    promotionalCode,
  }),
);

export { orderTotalsConfirmationPagePropsState, orderTotalsOrderFormState };
