// Types
import { RootState } from 'types/RootState.types';

export const getOrderFormAmendItemsFailedSelector =
  (state: RootState) => state.orderForm?.amendItemsFailed ?? false;
export const getOrderFormAmendQuantitiesApiCallActiveSelector =
  (state: RootState) => state.orderForm?.amendQuantitiesApiCallActive ?? false;
export const getOrderFormAmountsSelector = (state: RootState) => state.orderForm?.amounts;
export const getOrderFormPaymentsSelector = (state: RootState) => state.orderForm?.payments;
export const getOrderFormPromoCodeAppliedSelector = (state: RootState) => state.orderForm?.promoCodeApplied;
export const getGrandTotalSelector = (state: RootState) => state.orderForm?.amounts?.grandTotal;
export const getGrandTotalRawSelector = (state: RootState) => state.orderForm?.amounts?.grandTotalRaw;
export const getBasketTotalRawSelector = (state: RootState) => state.orderForm?.amounts?.basketTotalRaw;
export const getDeliveryChargeRawSelector = (state: RootState) => state.orderForm?.amounts?.deliveryChargeRaw;
export const getOutstandingBalanceSelector = (state: RootState) => state.orderForm?.amounts?.outstandingBalance;
export const getOutstandingBalanceRawSelector = (state: RootState) => state.orderForm?.amounts?.outstandingBalanceRaw;
export const getItemsDiscountTotalRawSelector = (state: RootState) => state.orderForm?.amounts?.itemsDiscountTotalRaw;
export const getOrderFormRewardsDiscountTotalRawSelector =
  (state: RootState) => state.orderForm?.amounts?.rewardsDiscountTotalRaw;
export const getOrderFormAmountsGiftCardSelector =
  (state: RootState) => state.orderForm?.amounts?.paymentTotals?.giftCard;
export const getOrderFormAmountsGiftCardRawSelector =
  (state: RootState) => state.orderForm?.amounts?.paymentTotals?.giftCardRaw;
export const getOrderFormAmountsGiftVoucherSelector =
  (state: RootState) => state.orderForm?.amounts?.paymentTotals?.giftVoucher;
export const getOrderFormAmountsGiftVoucherRawSelector =
  (state: RootState) => state.orderForm?.amounts?.paymentTotals?.giftVoucherRaw;
export const getIsRewardApplicableSelector = (state: RootState) => state.orderForm?.rewardApplicable;
export const getBasketAmendCreditTypeGroupSelector = (state: RootState) => state.orderForm?.basketAmendCreditTypeGroup;
export const getOrderFormDeliveryAddressSelector = (state: RootState) => state.orderForm?.deliveryAddress;
export const getEditBasketItemOutOfStockOverlayIdsSelector =
  (state: RootState) => state.orderForm?.editBasketItemOutOfStockOverlayIds;
export const getEditBasketItemRemoveOverlayIdsSelector =
  (state: RootState) => state.orderForm?.editBasketItemRemoveOverlayIds;
export const getEditBasketItemUpdateActiveIdsSelector =
  (state: RootState) => state.orderForm?.editBasketItemUpdateActiveIds;
export const getEditBasketRemoveItemApiCallActiveIdsSelector =
  (state: RootState) => state.orderForm?.editBasketRemoveItemApiCallActiveIds;
export const getErrorItemIdSelector = (state: RootState) => state.orderForm?.errorItemId;
export const getErrorMessageSelector = (state: RootState) => state.orderForm?.errorMessage;
export const getIsPosCreditSubmissionErrorSelector =
  (state: RootState) => state.orderForm?.isPosCreditSubmissionError || false;
export const getOrderFormItemsSelector = (state: RootState) => state.orderForm?.items;
export const getOrderFormLimitedStockItemsSelector = (state: RootState) => state.orderForm?.limitedStockItems;
export const getOrderFormNotAvailableForPartnerDiscountItemsSelector =
  (state: RootState) => state.orderForm?.notAvailableForPartnerDiscountItems;
export const getOrderFormOrderNeedsToBeRebatchedSelector =
    (state: RootState) => state.orderForm?.orderNeedsToBeRebatched ?? false;
export const getOrderFormOutOfStockItemsSelector = (state: RootState) => state.orderForm?.outOfStockItems;
export const getPosCreditIneligibleItemsSelector = (state: RootState) => state.orderForm?.posCreditIneligibleItems;
export const getPosCreditBasketExcessSelector = (state: RootState) => state.orderForm?.posCreditBasketExcess;
export const getPosCreditBasketLimitExceededSelector =
  (state: RootState) => state.orderForm?.posCreditBasketLimitExceeded || false;
export const getPosCreditCollectionPointChangeRequiredSelector =
  (state: RootState) => state.orderForm?.posCreditCollectionPointChangeRequired || false;
export const getOrderShouldHideRewardsValueSelector =
  (state: RootState) => state.orderForm?.shouldHideRewardsValue ?? false;
export const getRebatchOrderApiCallActiveSelector =
  (state: RootState) => state.orderForm?.rebatchOrderApiCallActive || false;
export const getOrderFormIsClickCollectOrderSelector =
  (state: RootState) => state.orderForm?.isClickCollectOrder ?? false;
export const getOrderFormIsGiftReceiptOrderSelector =
  (state: RootState) => state.orderForm?.isGiftReceiptOrder ?? false;
export const getOrderDetailsIsClickCollectOrderSelector =
  (state: RootState) => state.orderForm?.orderDetails?.isClickCollectOrder ?? false;
export const getOrderFormOrderDetailsSelector = (state: RootState) => state.orderForm?.orderDetails;
export const getRemoveBasketItemsApiCallActiveSelector =
  (state: RootState) => state.orderForm?.removeBasketItemsApiCallActive || false;
export const getRemoveBasketItemsFailedSelector =
  (state: RootState) => state.orderForm?.removeBasketItemsFailed || false;
export const getShowPOSCreditBasketAmendModalSelector =
  (state: RootState) => state.orderForm?.showPOSCreditBasketAmendModal ?? false;
export const getSubmitOrderFailedSelector = (state: RootState) => state.orderForm?.submitOrderFailed || false;
export const getTradeInQuotesSelector = (state: RootState) => state.orderForm?.tradeInQuotes;
export const getAllItemsInBasketUnavailableSelector =
  (state: RootState) => state.orderForm?.unavailableItemInfo?.allItemsInBasketUnavailable || false;
export const getOrderFormUnavailableItemInfoItemsSelector =
  (state: RootState) => state.orderForm?.unavailableItemInfo?.unavailableItemsInBasket;
export const getUseDeliveryAddressAsBillingAddressSelector =
  (state: RootState) => state.orderForm?.useDeliveryAddressAsBillingAddress ?? true;
export const getVoucherUnderspendSelector = (state: RootState) => state.orderForm?.voucherUnderspend;
export const getPaymentPageCompleteSelector = (state: RootState) => state.orderForm?.getPaymentPageComplete ?? false;
export const getOrderFormRemoveGiftOptionLoadingSelector =
  (state: RootState) => state.orderForm?.removeGiftOptionLoading ?? false;
export const getOrderFormPosCreditSecondaryRulesBrokenSelector =
  (state: RootState) => state.orderForm?.posCreditSecondaryRulesBroken ?? false;

export const getIsProcessingOrderFormApiSelector = (state: RootState) => (
  state.orderForm?.putItemQuantityApiCallActive ||
  state.orderForm?.getItemsApiCallActive ||
  state.orderForm?.removeBasketItemsApiCallActive
);

export const getApplyRewardsFailedSelector = (state: RootState) => state.orderForm?.applyRewardsFailed ?? false;
export const getRemoveRewardsFailedSelector = (state: RootState) => state.orderForm?.removeRewardsFailed ?? false;
export const getRewardsSelector = (state: RootState) => state.orderForm?.rewards;
export const getRewardApplicableSelector = (state: RootState) => state.orderForm?.rewardApplicable ?? false;
export const getPromoCodeAppliedSelector = (state: RootState) => state.orderForm?.promoCodeApplied;
export const getApplyPromoCodeFailedSelector = (state: RootState) => state.orderForm?.applyPromoCodeFailed;
export const getApplyPromoCodeFailedCodeSelector = (state: RootState) => state.orderForm?.applyPromoCodeFailedCode;
export const getApplyPromoCodeLoadingSelector = (state: RootState) => state.orderForm?.applyPromoCodeLoading;
export const getIsLoadingSelector = (state: RootState) => state.orderForm?.isLoading;
export const getApplyRewardsApiCallActiveSelector =
  (state: RootState) => state.orderForm?.applyRewardsApiCallActive ?? false;
export const getRemoveRewardsApiCallActiveSelector =
  (state: RootState) => state.orderForm?.removeRewardsApiCallActive ?? false;
export const getRewardsDiscountTotalSelector =
  (state: RootState) => state.orderForm?.amounts?.rewardsDiscountTotal;
export const getOmsOrderIdSelector = (state: RootState) => state.orderForm?.omsOrderId;
export const getOrderFormomsOrderIdSelector = (state: RootState) => state.orderForm?.omsOrderId;

export const orderFormContainsBladedItemsOnlySelector = (state: RootState) => {
  const items = getOrderFormItemsSelector(state) ?? [];
  return items.every(item => item.productType?.toLowerCase() === 'knife');
};

export const orderFormBasketContainsAlcoholSelector = (state: RootState) => {
  const items = getOrderFormItemsSelector(state) ?? [];
  return items.some(item => item.productType === 'Alcohol');
};

export const getEditBasketIsRemoveSelector = (state: RootState) => state.orderForm?.editBasketIsRemove || false;
