import { createSelector } from 'reselect';

// Config
import deliveryConstants from '../../constants/deliveryConstants';
import { CHANNEL_ISLANDS_POSTCODE_PREFIXES } from '../../constants/postcodeConstants';
import { getSelectedCollectionPointPostcodeSelector } from '../../redux/reducers/click-and-collect/clickAndCollectSelector';
import {
  getConfirmedDeliveryAddressPostcodeSelector,
  getSelectedDeliveryChoiceIdSelector,
} from '../../redux/reducers/delivery/deliverySelector';

const vatRefundMessageState = createSelector(
  [
    getSelectedDeliveryChoiceIdSelector,
    getConfirmedDeliveryAddressPostcodeSelector,
    getSelectedCollectionPointPostcodeSelector,
  ],
  (selectedDeliveryChoiceId, deliveryPostcode = '', clickAndCollectPostcode = '') => {
    const isDelivery = selectedDeliveryChoiceId === deliveryConstants.DELIVERY;
    const isClickAndCollect = selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT;

    let postcode = '';
    if (isDelivery) {
      postcode = deliveryPostcode;
    } else if (isClickAndCollect) {
      postcode = clickAndCollectPostcode;
    }

    if (postcode) {
      return CHANNEL_ISLANDS_POSTCODE_PREFIXES.some((prefix) => postcode.startsWith(prefix));
    }

    return false;
  },
);

export default vatRefundMessageState;
