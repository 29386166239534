import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

// Design System
import { Message } from 'jl-design-system/components/message/Message';
import { Modal } from 'jl-design-system/components/modal/Modal';

// Types
import { AppDispatch } from 'types/RootState.types';
import { ProductProps } from 'types/Product.types';
import { LimitedStockModalProps, PrevPropsType } from './LimitedStockModal.types';

// Config
import { shouldShowLimitedStockModal } from './LimitedStockModal.constants';
import limitedStockModalState from './LimitedStockModal.state';
import {
  showLimitedStockModal,
  rebatchOrderAfterBasketEdit,
  batchUpdateItemQuantity,
} from '../../redux/actions/edit-basket/editBasketActions';
import { getFormId } from '../../utils/form/configs/itemQuantity';
import { disabledEscKeyHandler } from '../../utils/helpers/eventKeyActions';
import { pluraliseItemWord, pluraliseQuantityWord } from '../../utils/helpers/pluraliseItem';
import returnToJL from '../../utils/helpers/returnToJL';

// Components
import Container from '../container';
import OrderSummary from '../order-summary';

// Styles
import styles from './limited-stock-modal.scss';

const LimitedStockModal = ({
  prevLimitedStockItems,
}: LimitedStockModalProps) => {
  const prevPropsRef = useRef<PrevPropsType>();
  const dispatch: AppDispatch = useDispatch();
  const {
    amendItemsFailed,
    amendQuantitiesApiCallActive,
    form = {},
    isPosCreditSubmissionError,
    limitedStockItems = [],
    orderNeedsToBeRebatched,
    pathname,
  } = useSelector(limitedStockModalState);

  useEffect(() => {
    document.addEventListener('keydown', disabledEscKeyHandler);

    return () => {
      document.removeEventListener('keydown', disabledEscKeyHandler);
    };
  }, []);

  useEffect(() => {
    const prevProps = prevLimitedStockItems || prevPropsRef.current;

    if (prevProps && hasModalJustOpened(prevProps, { limitedStockItems })) {
      handleModalOpen();
    }

    if (prevProps && hasModalJustClosed(prevProps, { limitedStockItems })) {
      handleModalClose();
    }

    prevPropsRef.current = { limitedStockItems };
  }, [limitedStockItems]);

  const handleModalOpen = () => {
    dispatch(showLimitedStockModal(pathname));
  };

  const handleModalClose = () => {
    if (orderNeedsToBeRebatched) {
      dispatch(rebatchOrderAfterBasketEdit());
    }
  };

  const hasModalJustOpened = (
    prevProps: { limitedStockItems: ProductProps[] }, collection: { [key: string]: ProductProps[] },
  ) => {
    const prevKeyShorterThanCurrentKey =
      (key: string) => get(prevProps, key, []).length === 0 && collection[key].length > 0;
    const modalOpened = Object.keys(collection).some(prevKeyShorterThanCurrentKey);
    return modalOpened;
  };

  const hasModalJustClosed = (
    prevProps: { limitedStockItems: ProductProps[] }, collection: { [key: string]: ProductProps[] },
  ) => {
    const prevKeyLongerThanCurrentKey = (key: string) => get(prevProps, key, []).length && !collection[key].length;
    const modalClosed = Object.keys(collection).some(prevKeyLongerThanCurrentKey);
    return modalClosed;
  };

  const getItemIds = (collection: ProductProps[]) => collection.reduce<{ [key: string]: number }>((acc, item) => ({
    ...acc,
    [item.id as string]: getNewQuantity(item),
  }), {});

  const getNewQuantity = (item: ProductProps) => form?.[getFormId(item.id)]?.values?.itemQuantity
    || (Number(item?.availability?.stockLevel))
    || 0;

  const amendQuantities = () => {
    if (limitedStockItems.length > 0) {
      dispatch(batchUpdateItemQuantity(getItemIds(limitedStockItems)));
    }
  };

  const limitedStockItemsInBasket = shouldShowLimitedStockModal({ limitedStockItems });

  if (!limitedStockItemsInBasket) {
    return null;
  }

  const returnToBasket = () => {
    window.location.href = returnToJL('basket');
  };

  const singleItem = limitedStockItems.length === 1;
  const posCreditText = isPosCreditSubmissionError ? ' We have cancelled your payment plan with Creation Consumer Finance Ltd.' : '';

  const bodyText = `Sorry, we have reduced the quantity for the following ${pluraliseItemWord(singleItem)} due to limited stock.${posCreditText}`;
  const headerText = 'Limited Stock';

  let primaryActionProps = {};
  let secondaryActionProps = {};

  if (amendItemsFailed) {
    primaryActionProps = {
      primaryButtonProps: {
        disabled: amendQuantitiesApiCallActive,
      },
      primaryButtonText: 'Return to basket',
      onPrimaryButtonClick: returnToBasket,
    };

    secondaryActionProps = {
      underlinedButtonText: '',
    };
  } else {
    primaryActionProps = {
      primaryButtonProps: {
        submitting: amendQuantitiesApiCallActive,
      },
      primaryButtonText: `Amend ${pluraliseQuantityWord(singleItem)} and continue`,
      onPrimaryButtonClick: amendQuantities,
    };

    secondaryActionProps = {
      underlinedButtonText: 'Return to basket',
      underlinedButtonProps: {
        disabled: amendQuantitiesApiCallActive,
      },
      onUnderlinedButtonClick: returnToBasket,
    };
  }

  return (
    <Modal
      className={styles.modal}
      isOpen
      title={headerText}
      {...primaryActionProps}
      {...secondaryActionProps}
    >
      <Container testId="modal-limited-stock">
        <p>{bodyText}</p>

        {amendItemsFailed && (
          <Message
            title="Sorry, we couldn't amend the quantity. Please return to basket."
            type="error"
          />
        )}

        <Container
          borderTop="grey-15"
          marginY="3"
        >
          <OrderSummary
            displayHeaders={false}
            form={form}
            hideOutOfStockText
            hideSubtotals
            items={limitedStockItems}
            modalView
            preventEditing
            showReducedQuantityDetails
          />
        </Container>
      </Container>
    </Modal>
  );
};

export default LimitedStockModal;
