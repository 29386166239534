import { AnyAction } from 'redux';
import isEmpty from 'lodash/isEmpty';

// Types
import { DeliveryState } from 'types/RootState.types';

// Config
import {
  deliveryContainsIncompatibleServices,
  deliveryContainsUndeliverableServices,
  getServicesThatNeedRemoving,
} from './deliveryContainsUndeliverableServices';
import getSelectedFulfilmentOption from './getSelectedFulfilmentOption';
import setConfirmedDeliveryAddress from './setConfirmedDeliveryAddress';
import { getItemsNeedingAgeVerification } from '../../actions/age-verification/ageVerificationActions';
import getUndeliverableItems from '../../../utils/delivery/getUndeliverableItems';

function getDeliveriesState(state: DeliveryState, action: AnyAction) {
  const orderForm = action?.result?.orderForm ?? {};
  const deliveries = orderForm?.deliveries ?? state.deliveries ?? [];
  const items = orderForm?.items ?? state.items ?? [];
  const deliveryOptions = orderForm?.deliveryOptions ?? state.deliveryOptions ?? {};

  const itemsNeedingAgeVerification = getItemsNeedingAgeVerification(items);
  const undeliverableItemsInBasket = getUndeliverableItems(deliveries, items);
  const confirmedDeliveryAddress = setConfirmedDeliveryAddress(state, action);
  const selectedDeliveryChoiceId = getSelectedFulfilmentOption({
    deliveryOptions,
    selectedDeliveryChoiceId: state.selectedDeliveryChoiceId,
    action,
  });

  const commonValues = {
    itemsNeedingAgeVerification,
    confirmedDeliveryAddress,
    deliveryOptions,
    selectedDeliveryChoiceId,
    items,
  };

  const deliveryContainsIncompatibleServicesResult = deliveryContainsIncompatibleServices(deliveries);
  const deliveryContainsUndeliverableServicesResult = deliveryContainsUndeliverableServices(deliveries);

  if (
    !isEmpty(undeliverableItemsInBasket) &&
    !deliveryContainsIncompatibleServicesResult &&
    !deliveryContainsUndeliverableServicesResult
  ) {
    return {
      ...state,
      ...commonValues,
      undeliverableItemsInBasket,
    };
  }

  const servicesThatNeedRemoving = getServicesThatNeedRemoving(deliveries, items);

  return {
    ...state,
    ...commonValues,
    undeliverableItemsInBasket: [],
    deliveryContainsIncompatibleServices: deliveryContainsIncompatibleServicesResult,
    servicesThatNeedRemoving,
  };
}

export default getDeliveriesState;
