import { lazy } from 'react';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "editBasketModal" */ './EditBasketModal')),
);

const LazyComponentWithSuspense = withSuspense<{}>(LazyComponent, 'EditBasketModal');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as EditBasketModal };
