import React from 'react';
import { createRoot } from 'react-dom/client';

// jl-design-system
import DataActionManager from 'jl-design-system/components/data-action-manager/DataActionManager';
// lodash
import get from 'lodash/get';
// redux
import { dataAction } from './redux/actions/data-action-link/dataActionLinkActions';
import mobileAppsActions from './redux/actions/mobileApps/mobileAppsActions';
// utils
import configureStore from './redux/configureStore';
import { URL_FEATURES } from './constants/endpointConstants';
import routeConstants from './constants/routeConstants';
import createBrowserHistory from './utils/history/history';
import api from './utils/api/api';
import { initLogging } from './utils/logging/logging-utils';
import unionWithDevFeatures from './utils/features/feature-utils';
// components
import Root from './Root';

function onRender(store) {
  // initialise DataActionManager action
  DataActionManager.setDataAction((...args) => store.dispatch(dataAction(...args)));

  store.dispatch(initLogging());
}

export function initStore(initialState = {}) {
  const history = createBrowserHistory();
  const { store, persistor } = configureStore(history, initialState);

  return { history, store, persistor };
}

export function init(initialState = {}) {

  const { history, store, persistor } = initStore(initialState);

  // Set up Apps actions
  window.appsActions = mobileAppsActions(store);

  const container = document.getElementById('page-root');
  if (container) {
    const reactDOM = createRoot(container);

    const root = <Root history={history} persistor={persistor} store={store} />;
    reactDOM.render(root);

    if (module.hot) {
      module.hot.accept('./Root', () => {
        const NewRoot = require('./Root').default; // eslint-disable-line global-require
        const root = <NewRoot history={history} persistor={persistor} store={store} />;
        reactDOM.render(root);
      });
    }

    setTimeout(() => onRender(store), 0);
  }
}

const getFeatures = () => (
  api({
    path: URL_FEATURES,
    config: { method: 'GET' },
  }).then((response) => {
    const bffFeatures = get(response, 'features', []);
    return unionWithDevFeatures(bffFeatures);
  }).catch(() => [])
);

const skipGetFeaturesCallOnPaths = [
  routeConstants.LOGIN,
  routeConstants.WEB_HANDOVER,
  routeConstants.APPS_GUEST_HANDOVER,
  routeConstants.APPS_AUTHENTICATED_HANDOVER,
  routeConstants.CALLBACK_LOGIN_AUTHENTICATED,
  routeConstants.CALLBACK_LOGIN_GUEST,
  routeConstants.CALLBACK_ORDER_CLAIM,
];

export default async function initApp() {
  window.timeToReactLoaded = Date.now();

  const features = skipGetFeaturesCallOnPaths.includes(window.location.pathname) ? [] : await getFeatures();

  return init({
    config: {
      features,
    },
  });
}

initApp();
