import assignIn from 'lodash/assignIn';

// Types
import { AddressRecordProps } from 'types/Address.types';
import { DeliveryProps } from 'types/Delivery.types';

// Config
import { isCollectPlusRelatedType } from '../collection/getCollectPlusRelatedStores';
import {
  isGreenVanFleet,
  isGreenVanFleetExCatchment,
  isGreenVanFleetUnavailable,
  isMadeToMeasureDelivery,
  isOneManDayDelivery,
  isOneManEveningDelivery,
  isOneManMorningDelivery,
  isOneManPremiumDelivery,
} from '../delivery/deliveryHelper';
import { getDeliveryMethodText } from '../delivery/getDeliveryDetails';

function getDeliveryTypes(delivery: DeliveryProps) {
  const { fulfilment, type } = delivery;

  return {
    isGreenVanFleet: isGreenVanFleet(type),
    isGreenVanFleetExCatchment: isGreenVanFleetExCatchment(type, fulfilment),
    isGreenVanFleetUnavailable: isGreenVanFleetUnavailable(type, fulfilment),
    isMadeToMeasureDelivery: isMadeToMeasureDelivery(type),
    isOneManDayDelivery: isOneManDayDelivery(delivery),
    isOneManEveningDelivery: isOneManEveningDelivery(delivery),
    isOneManMorningDelivery: isOneManMorningDelivery(delivery),
    isOneManPremiumDelivery: isOneManPremiumDelivery(delivery),
  };
}

export function getGiftMessageLines(deliveries: DeliveryProps[]) {
  const deliveriesWithGiftMessage =
    deliveries.filter((delivery: DeliveryProps) => (delivery?.giftMessageLines ?? []).length > 0);

  if (deliveriesWithGiftMessage.length > 1) {
    return 'Your order contains multiple gift messages';
  }

  return deliveriesWithGiftMessage?.[0]?.giftMessageLines;
}

function getDeliveryInstructions(deliveries: DeliveryProps[]) {
  const deliveriesWithDeliveryInstructions = deliveries.find(
    delivery => delivery?.fulfilment?.deliveryInfo?.deliveryInstructions,
  );

  return deliveriesWithDeliveryInstructions?.fulfilment?.deliveryInfo?.deliveryInstructions;
}

function getDeliveryDetails({
  deliveries,
  isClickCollectOrder,
}: { deliveries: DeliveryProps[]; isClickCollectOrder: boolean }) {
  return deliveries?.map((delivery: DeliveryProps) => {
    const additionalInformation = delivery?.fulfilment?.additionalInformation ?? '';
    const deliveryInfo = delivery?.fulfilment?.deliveryInfo ?? {};
    const {
      collectionDateTime,
      ownerId,
    } = delivery?.fulfilment?.collectionInfo ?? {};
    const deliveryTypes = getDeliveryTypes(delivery);
    const method = delivery?.fulfilment?.description ?? '';
    const oneManPremiumDeliverySelectedDate = delivery?.fulfilment?.deliveryInfo?.date;
    const {
      id,
      isGiftReceiptOrder,
      isGvfSlotGreaterThanTwoHours,
      items,
      type,
    } = delivery;

    const deliveryMethodText = getDeliveryMethodText({
      isGreenVanFleet: deliveryTypes.isGreenVanFleet,
      isGreenVanFleetExCatchment: deliveryTypes.isGreenVanFleetExCatchment,
      isGreenVanFleetUnavailable: deliveryTypes.isGreenVanFleetUnavailable,
      isMadeToMeasureDelivery: deliveryTypes.isMadeToMeasureDelivery,
      method,
      deliveryInfo,
    });

    const deliveryInfoText = {
      additionalInformation,
      deliveryInfo,
      collectionTime: collectionDateTime,
      ownerId,
      oneManPremiumDeliverySelectedDate,
      isClickCollectOrder,
      isGvfSlotGreaterThanTwoHours,
      ...deliveryTypes,
    };

    return {
      additionalInformation,
      charge: delivery?.fulfilment?.charge ?? '',
      deliveryDescription: method,
      deliveryInfo,
      deliveryInfoText,
      deliveryMethodText,
      giftMessageLines: delivery?.giftMessageLines ?? [],
      id,
      isGiftReceiptOrder,
      isGvfSlotGreaterThanTwoHours,
      items,
      oneManPremiumDeliverySelectedDate,
      deliveryInstructions: deliveryInfo?.deliveryInstructions ?? '',
      type,
      ...deliveryTypes,
    };
  });
}

export default function getOrderDetails({
  billingAddress,
  deliveries,
  deliveryAddress,
  isClickCollectOrder = false,
  payments,
}: {
  billingAddress?: AddressRecordProps;
  deliveries: DeliveryProps[];
  deliveryAddress?: AddressRecordProps;
  isClickCollectOrder?: boolean;
  payments?: any;
}) {
  const deliveriesLength = deliveries?.length;
  const isMultiBatch = deliveriesLength > 1;
  const { address, addressee, phoneNumber } = deliveryAddress ?? {};

  const orderDetails = {
    address,
    addressee,
    billingAddress,
    deliveriesLength,
    isClickCollectOrder,
    isMultiBatch,
    payments,
    phoneNumber,
    deliveries: getDeliveryDetails({ deliveries, isClickCollectOrder }),
    giftMessageLines: getGiftMessageLines(deliveries),
    deliveryInstructions: getDeliveryInstructions(deliveries),
  };

  if (isMultiBatch) {
    assignIn(orderDetails, {
      deliveryInfoText: 'Not all items can arrive together as they’re dispatched from different warehouses.',
      deliveryMethodText: `Your order will be separated into ${deliveriesLength} deliveries.`,
    });
  } else {
    assignIn(orderDetails, {
      deliveryInfoText: orderDetails.deliveries[0]?.deliveryInfoText,
      deliveryMethodText: orderDetails.deliveries[0]?.deliveryMethodText,
    });
  }

  if (isClickCollectOrder) {
    const collectionTime = deliveries?.[0]?.fulfilment?.collectionInfo?.collectionDateTime ?? '';
    const ownerId = deliveries?.[0]?.fulfilment?.collectionInfo?.ownerId ?? 0;
    assignIn(orderDetails, {
      collectionTime,
      ownerId,
      isCollectPlusOrder: isCollectPlusRelatedType(ownerId),
    });
  }

  return orderDetails;
}
