const isClickAndCollectAvailable = (deliveryOptions) => {
  if (!deliveryOptions) {
    return false;
  }

  const { clickCollectAvailable, collectPlusAvailable } = deliveryOptions;
  return !!(clickCollectAvailable || collectPlusAvailable);
};

export default isClickAndCollectAvailable;
