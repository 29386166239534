import { lazy } from 'react';

// Types
import { LeaveCheckoutModalProps as LazyComponentProps } from './LeaveCheckoutModal.types';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: "leaveCheckoutModal" */ './LeaveCheckoutModal')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'LeaveCheckoutModal');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as LeaveCheckoutModal };
