import isEqual from 'lodash/isEqual';

// Types
import { CollectionPointProps } from 'types/CollectionPoint.types';
import { AppClient, AppDispatch, AppGetState, RootState } from 'types/RootState.types';

// Config
import {
  SAVE_PREFERENCES_COLLECTION,
  SAVE_PREFERENCES_DELIVERY,
  SAVE_PREFERENCES_PAYMENT_CARD,
  SAVE_PREFERENCES_PAYMENT_METHOD,
  DELETE_PREFERENCES,
} from '../../../constants/actionConstants';
import {
  URL_PREFERENCES_COLLECTION,
  URL_PREFERENCES_DELIVERY,
  URL_PREFERENCES_PAYMENT_CARD,
  URL_PREFERENCES_DELETE,
  URL_PREFERENCES_PAYMENT_METHOD,
} from '../../../constants/endpointConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import userConstants from '../../../constants/userConstants';
import shouldSaveDefaultCollectionPoint from '../../../utils/collection/shouldSaveDefaultCollectionPoint';
import { shouldSaveDefaultAddress } from '../../../utils/delivery/shouldSaveDefaultAddress';
import { SAVE_AS_DEFAULT_FORM_ID } from '../../../utils/form/configs/saveAsDefault';

export const deletePreferences = () => ({
  type: DELETE_PREFERENCES,
  request: (client: AppClient) => client({ path: URL_PREFERENCES_DELETE, config: { method: 'DELETE' } }),
});

export const savePreferencesCollection = (body: {
  collectionPointId: any;
  default: boolean;
  preferredFulfilmentOption: boolean;
}) => ({
  type: SAVE_PREFERENCES_COLLECTION,
  request: (client: AppClient) => client({ path: URL_PREFERENCES_COLLECTION, config: { method: 'POST', body } }),
});

export const savePreferencesDelivery = (body: {
  addressId: string;
  default: boolean;
  preferredFulfilmentOption: boolean;
}) => ({
  type: SAVE_PREFERENCES_DELIVERY,
  request: (client: AppClient) => client({ path: URL_PREFERENCES_DELIVERY, config: { method: 'POST', body } }),
});

export const savePreferencesCard = (body: {
  addressId?: string | undefined;
  cardId: string;
  default?: boolean | undefined;
}) => ({
  type: SAVE_PREFERENCES_PAYMENT_CARD,
  request: (client: AppClient) => client({ path: URL_PREFERENCES_PAYMENT_CARD, config: { method: 'POST', body } }),
});

export const savePreferencesPaymentMethod = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const selectedPaymentType = getState().payment.selectedPaymentType;
  const paymentChoice = getState().user.customerPreferences?.paymentChoice;

  const { PAYPAL, APPLE_PAY, GOOGLE_PAY, SAVED_PAYMENT_CARD } = paymentTypeConstants;
  const {
    PAYMENT_PREFERENCE_PAYPAL,
    PAYMENT_PREFERENCE_APPLE_PAY,
    PAYMENT_PREFERENCE_GOOGLE_PAY,
    PAYMENT_PREFERENCE_SAVED_CARD,
  } = userConstants;
  let newPaymentPreference;

  switch (selectedPaymentType) {
    case PAYPAL:
      if (paymentChoice !== PAYMENT_PREFERENCE_PAYPAL) {
        newPaymentPreference = PAYMENT_PREFERENCE_PAYPAL;
      }
      break;
    case APPLE_PAY:
      if (paymentChoice !== PAYMENT_PREFERENCE_APPLE_PAY) {
        newPaymentPreference = PAYMENT_PREFERENCE_APPLE_PAY;
      }
      break;
    case GOOGLE_PAY:
      if (paymentChoice !== PAYMENT_PREFERENCE_GOOGLE_PAY) {
        newPaymentPreference = PAYMENT_PREFERENCE_GOOGLE_PAY;
      }
      break;
    default:
      if (selectedPaymentType?.includes(SAVED_PAYMENT_CARD) && paymentChoice !== PAYMENT_PREFERENCE_SAVED_CARD) {
        newPaymentPreference = PAYMENT_PREFERENCE_SAVED_CARD;
      }
  }

  if (!newPaymentPreference) return;

  const body = { paymentPreference: newPaymentPreference };

  dispatch({
    type: SAVE_PREFERENCES_PAYMENT_METHOD,
    request: (client: AppClient) => client({ path: URL_PREFERENCES_PAYMENT_METHOD, config: { method: 'POST', body } }),
  });
};

export function shouldSavePreferenceOrUpdateAddress(state: RootState) {
  const selectedPaymentType = state?.payment?.selectedPaymentType;

  if (selectedPaymentType) {
    const parsedSelectedPaymentType = selectedPaymentType.split(':');
    const selectedPaymentTypeName = parsedSelectedPaymentType[0];
    const selectedPaymentCardId = parsedSelectedPaymentType[1];

    if (selectedPaymentTypeName === paymentTypeConstants.SAVED_PAYMENT_CARD) {
      const savedCards = state?.user?.savedPaymentCards;
      const savedDefaultCard = state?.user?.defaultSavedPaymentDetail?.id;
      const savedCardAddressId = savedCards?.find((card) => card.id === selectedPaymentCardId)?.addressId;
      const billingAddressId = state?.user?.selectedBillingAddress?.id;

      const shouldSaveWithNewAddress = !isEqual(savedCardAddressId, billingAddressId);
      const shouldUpdateCardPreference = !isEqual(selectedPaymentCardId, savedDefaultCard);
      if (shouldSaveWithNewAddress || shouldUpdateCardPreference) {
        return { billingAddressId, selectedPaymentCardId, shouldUpdateCardPreference };
      }
    }
  }

  return {};
}

export function getPaymentCardBody(
  shouldUpdateCardPreference: boolean,
  billingAddressId: string | undefined,
  selectedPaymentCardId: string,
) {
  return {
    cardId: selectedPaymentCardId,
    ...(shouldUpdateCardPreference && { default: true }),
    ...(billingAddressId && { addressId: billingAddressId }),
  };
}

export function shouldSaveNonDefaultCollectionPoint(params: {
  confirmedCollectionPoint?: CollectionPointProps;
  savedCollectionPoints?: CollectionPointProps[];
}) {
  const { savedCollectionPoints = [], confirmedCollectionPoint } = params;

  if (!confirmedCollectionPoint) return null;
  const collectionPointAlreadySaved = !!savedCollectionPoints.find((point) => point.id === confirmedCollectionPoint.id);
  return !collectionPointAlreadySaved;
}

export const saveDeliveryPreferences = ({ createAccount = false } = {}) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      addressBook: { addressIdToBeSavedAsDefault } = {},
      createAccount: { userAddress = {} } = {},
      delivery: { confirmedDeliveryAddress = {} } = {},
    } = getState();

    const saveAddressAsDefaultId = createAccount ? userAddress?.id : addressIdToBeSavedAsDefault;

    if (saveAddressAsDefaultId) {
      return dispatch(
        savePreferencesDelivery({
          addressId: saveAddressAsDefaultId,
          default: true,
          preferredFulfilmentOption: true,
        }),
      );
    }

    if (confirmedDeliveryAddress.id) {
      if (shouldSaveDefaultAddress(getState())) {
        return dispatch(
          savePreferencesDelivery({
            addressId: confirmedDeliveryAddress.id,
            default: true,
            preferredFulfilmentOption: true,
          }),
        );
      }

      return dispatch(
        savePreferencesDelivery({
          addressId: confirmedDeliveryAddress.id,
          default: false,
          preferredFulfilmentOption: true,
        }),
      );
    }

    return {};
  };

export const saveCollectionPreferences = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const collectionPointId =
    getState()?.form?.[SAVE_AS_DEFAULT_FORM_ID]?.values?.saveCollectionPointAsDefault ?? undefined;
  if (collectionPointId) {
    return dispatch(
      savePreferencesCollection({
        collectionPointId,
        default: true,
        preferredFulfilmentOption: true,
      }),
    );
  }

  const savedCollectionPoints = getState().user?.collectionPoints;
  const confirmedCollectionPoint = getState().clickAndCollect?.confirmedCollectionPoint;

  if (shouldSaveDefaultCollectionPoint(getState())) {
    return dispatch(
      savePreferencesCollection({
        collectionPointId: confirmedCollectionPoint?.id,
        default: true,
        preferredFulfilmentOption: true,
      }),
    );
  }

  if (shouldSaveNonDefaultCollectionPoint({ confirmedCollectionPoint, savedCollectionPoints })) {
    return dispatch(
      savePreferencesCollection({
        collectionPointId: confirmedCollectionPoint?.id,
        default: false,
        preferredFulfilmentOption: true,
      }),
    );
  }

  return {};
};

export const saveCardPreferences = () => (dispatch: AppDispatch, getState: AppGetState) => {
  // Save card as default or update saved card address
  const {
    billingAddressId = '',
    selectedPaymentCardId = '',
    shouldUpdateCardPreference = false,
  } = shouldSavePreferenceOrUpdateAddress(getState());
  const shouldUpdateCardAddress = !!billingAddressId && !!selectedPaymentCardId;

  if (shouldUpdateCardPreference || shouldUpdateCardAddress) {
    const paymentCardBody = getPaymentCardBody(shouldUpdateCardPreference, billingAddressId, selectedPaymentCardId);
    dispatch(savePreferencesCard(paymentCardBody));
  }
};
