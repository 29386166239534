// Types
import { DeliveryProps } from 'types/Delivery.types';
import { ProductProps } from 'types/Product.types';

const transformDeliveries = (deliveries: DeliveryProps[] | undefined, items: ProductProps[] | undefined) =>
  deliveries?.map((delivery: DeliveryProps) => {
    const itemIds = delivery?.items?.map(d => d.id);
    const deliveryItems = items?.filter((item: ProductProps) => itemIds?.includes(item.id));

    return {
      ...delivery,
      ...(deliveryItems && deliveryItems?.length > 0 && { items: deliveryItems }),
    };
  });

export default transformDeliveries;
