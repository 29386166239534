// Types
import { CollectionPointProps } from 'types/CollectionPoint.types';

// Config
import { isCollectPlusRelatedType } from '../../../utils/collection/getCollectPlusRelatedStores';
import sortCollectionPointsByDistance from '../../../utils/collection/sortCollectionPointsByDistance';

function getFilteredCollectionPoints(collectionPoints: CollectionPointProps[]) {
  let filteredCollectionPoints: CollectionPointProps[] = [];
  filteredCollectionPoints = filteredCollectionPoints.concat(
    collectionPoints.filter(
      (collectionPoint) => collectionPoint.ownerId !== undefined && !isCollectPlusRelatedType(collectionPoint?.ownerId),
    ),
  );

  let collectPlusPoints = collectionPoints.filter(
    (collectionPoint) => collectionPoint.ownerId !== undefined && isCollectPlusRelatedType(collectionPoint.ownerId),
  );

  collectPlusPoints = collectPlusPoints.slice(0, 9);

  filteredCollectionPoints = filteredCollectionPoints.concat(collectPlusPoints);

  return sortCollectionPointsByDistance(filteredCollectionPoints);
}

export default getFilteredCollectionPoints;
