// Design System
import UnitInput from 'jl-design-system/elements/unit-input/UnitInput';
import { numbersPositiveOnly } from 'jl-design-system/form/normalizers/restrictInput';
import { numbersPositiveRegex } from 'jl-design-system/form/regex';

export function calcMaxValueFromAvailability(stockLevel, quantityLimit) {
  const limit = parseInt(quantityLimit, 10);
  const stock = Number(stockLevel);
  const lowestValue = Math.min(limit, stock);

  // return whichever is lowest, stock level or quantity limit
  if (Number.isInteger(lowestValue)) return lowestValue;

  // if there's a quantity limit, return this
  if (Number.isInteger(limit)) return limit;

  // return the stock level
  return stock;
}

export const ITEM_QUANTITY_FORM = 'itemQuantityForm';
export const ITEM_QUANTITY_INPUT_ID = 'itemQuantity';
export function getFormId(id) {
  return `${ITEM_QUANTITY_FORM}-${id}`;
}

export default function getItemQuantityFormConfig({
  changeHandler,
  editBasket,
  isItemFabric = false,
  item,
}) {
  const { id, availability: { stockLevel, quantityLimit } = {} } = item;
  const selectMaxValue = calcMaxValueFromAvailability(stockLevel, quantityLimit);

  if (isItemFabric) {
    let debounceTimeout = null;
    const debouncedChangeHandler = (event) => {
      const inputValue = event.target.value;

      clearTimeout(debounceTimeout);

      if (typeof changeHandler === 'function') {
        debounceTimeout = setTimeout(() => {
          changeHandler(inputValue);
        }, 500);
      }
    };

    return {
      id: getFormId(id),
      fields: [{
        component: UnitInput,
        id: ITEM_QUANTITY_INPUT_ID,
        label: 'Quantity',
        name: ITEM_QUANTITY_INPUT_ID,
        normalize: editBasket ? undefined : numbersPositiveOnly,
        onChange: debouncedChangeHandler,
        type: 'tel',
        maxLength: 3,
        regex: numbersPositiveRegex,
        required: true,
        selectMaxThreshold: 10,
        validationMessageKeys: {
          maxLength: 'Please enter a number between 1 and 999',
          regex: 'Please enter a number between 1 and 999',
        },
        unitText: 'metre(s)',
        props: {
          id: `${ITEM_QUANTITY_INPUT_ID}-${id}`,
          name: `${ITEM_QUANTITY_INPUT_ID}-${id}`,
        },
      }],
    };
  }

  return {
    id: getFormId(id),
    maxValue: selectMaxValue,
  };
}
