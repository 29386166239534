// Config
import deliveryConstants from '../../../constants/deliveryConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';

// Components
import CheckboxReduxWrapper from '../../../components/checkbox-redux-wrapper';

export const SAVE_AS_DEFAULT_FORM_ID = 'saveAsDefaultForm';

export const SAVE_ADDRESS_AS_DEFAULT = {
  component: CheckboxReduxWrapper,
  type: 'checkbox',
  id: 'saveAddressAsDefault',
  label: 'Set as your default delivery address',
  name: 'saveAddressAsDefault',
  checkedValue: 'true',
};

export const SAVE_COLLECTION_POINT_AS_DEFAULT = {
  component: CheckboxReduxWrapper,
  type: 'checkbox',
  id: 'saveCollectionPointAsDefault',
  label: 'Set as your default collection point',
  name: 'saveCollectionPointAsDefault',
  checkedValue: 'true',
};

export const SAVE_PAYMENT_FOR_FUTURE = {
  component: CheckboxReduxWrapper,
  type: 'checkbox',
  id: 'savePaymentForFuture',
  label: 'Save card for future purchases',
  name: 'savePaymentForFuture',
  checkedValue: 'true',
};

export function getSaveAsDefaultFormConfig(type, id, onChange) {
  switch (type) {
    case deliveryConstants.DELIVERY:
      return {
        id: SAVE_AS_DEFAULT_FORM_ID,
        fields: [
          {
            ...SAVE_ADDRESS_AS_DEFAULT,
            checkedValue: id || 'true',
          },
        ],
      };
    case deliveryConstants.CLICK_AND_COLLECT:
      return {
        id: SAVE_AS_DEFAULT_FORM_ID,
        fields: [
          {
            ...SAVE_COLLECTION_POINT_AS_DEFAULT,
            checkedValue: id || 'true',
          },
        ],
      };
    case paymentTypeConstants.CREDIT_CARD: {
      if (!id) {
        return {
          id: SAVE_AS_DEFAULT_FORM_ID,
          fields: [
            {
              ...SAVE_PAYMENT_FOR_FUTURE,
              checkedValue: 'true',
              onChange,
            },
          ],
        };
      }

      return {};
    }
    default:
      return {
        id: SAVE_AS_DEFAULT_FORM_ID,
        fields: [],
      };
  }
}

export function getSaveAsDefaultFieldIdByType(type) {
  switch (type) {
    case deliveryConstants.DELIVERY:
      return SAVE_ADDRESS_AS_DEFAULT.id;
    case deliveryConstants.CLICK_AND_COLLECT:
      return SAVE_COLLECTION_POINT_AS_DEFAULT.id;
    default:
      return '';
  }
}
