import { createSelector } from 'reselect';

// Config
import { getPricePromiseFeatureActiveSelector } from '../price-promise/PricePromise.state';
import { vatRefundMessageState } from '../vat-refund-message';
import { getIsApplicationSelector } from '../../redux/reducers/app/appSelector';
import { getConfirmationExpiredSignedInSelector, getConfirmationOrderSelector } from '../../redux/reducers/confirmation/orderConfirmationSelector';
import { getOrderFormItemsSelector } from '../../redux/reducers/order-form/orderFormSelector';
import {
  getIsGuestSelector,
  getShowMyJLAccountPromptSelector,
  getUserIsMyJLMemberSelector,
} from '../../redux/reducers/user/userSelector';
import {
  getJoinMyJLFailedSelector,
  getJoinMyJLSuccessSelector,
} from '../../redux/reducers/create-account/createAccountSelector';

const sidebarState = createSelector(
  [
    getConfirmationExpiredSignedInSelector,
    getIsApplicationSelector,
    getIsGuestSelector,
    getUserIsMyJLMemberSelector,
    getOrderFormItemsSelector,
    getConfirmationOrderSelector,
    getJoinMyJLFailedSelector,
    getJoinMyJLSuccessSelector,
    getShowMyJLAccountPromptSelector,
    getPricePromiseFeatureActiveSelector,
    vatRefundMessageState,
  ],
  (
    expiredSignedIn,
    isApplication,
    isGuest,
    isMyJLMember,
    items,
    order,
    joinMyJLFailed,
    joinMyJLSuccess,
    showMyJLAccountPrompt,
    pricePromiseFeatureActive,
    showVatRefundMessage,
  ) => ({
    expiredSignedIn,
    isApplication,
    isGuest,
    isMyJLMember,
    items,
    joinMyJLFailed,
    joinMyJLSuccess,
    order,
    pricePromiseFeatureActive,
    showMyJLAccountPrompt,
    showVatRefundMessage,
  }),
);

export default sidebarState;
