import { lazy } from 'react';

// Types
import { PricePromiseProps as LazyComponentProps } from './PricePromise.types';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: "pricePromise" */ './PricePromise')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'PricePromise');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as PricePromise };
