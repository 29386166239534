export default function makePostcodeReadOnly(addressFields) {
  const postcodeFieldIndex = addressFields?.findIndex(field => field?.id === 'postcode');

  const postcodeField = addressFields[postcodeFieldIndex];

  if (postcodeField) {
    const newField = {
      ...postcodeField,
      readOnly: true,
    };

    addressFields.splice(postcodeFieldIndex, 1, newField);
  }
}
