import { lazy } from 'react';

// Types
import { RadioButtonTileProps as LazyComponentProps } from './RadioButtonTile.types';

// Components
import withSuspense, { lazyRetry } from '../suspense';

const LazyComponent = lazy(() => lazyRetry(() => import(/* webpackChunkName: "radioButtonTile" */ './RadioButtonTile')));

const LazyComponentWithSuspense = withSuspense<LazyComponentProps>(LazyComponent, 'RadioButtonTile');

// Exports
export default LazyComponentWithSuspense;
export { LazyComponent as RadioButtonTile };
