import { replace } from 'connected-react-router';

// Types
import { AppClient, AppDispatch, AppGetState } from 'types/RootState.types';

// Config
import { findCollectionPoint, selectCollectionPoint } from '../click-and-collect/clickAndCollectActions';
import { getDeliveryPage, refreshDeliveryMethods } from '../delivery/deliveryActions';
import { rebatchOrder, getItems } from '../edit-basket/editBasketActions';
import { applyRewards } from '../payment/rewardsActions';
import {
  SET_PARTNER_DISCOUNT,
  SET_PARTNER_DISCOUNT_PROCESSING,
  REBATCH_ORDER,
} from '../../../constants/actionConstants';
import { URL_PARTNER_DISCOUNT } from '../../../constants/endpointConstants';
import routeConstants from '../../../constants/routeConstants';

export const togglePartnerDiscount =
  (partnerDiscountEnabled = false, shouldFetchPage = true, shouldRebatch = true) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
      const callPartnerDiscountMethod = partnerDiscountEnabled ? 'POST' : 'DELETE';

      const response = await dispatch({
        type: SET_PARTNER_DISCOUNT,
        request: (client: AppClient) =>
          client({
            path: URL_PARTNER_DISCOUNT,
            config: { method: callPartnerDiscountMethod },
          }),
        partnerDiscountEnabled,
        savedPaymentCards: getState()?.user?.savedPaymentCards ?? [],
      });

      if (response.type === `${SET_PARTNER_DISCOUNT}.SUCCESS` && shouldFetchPage) {
        const pathname = getState()?.router?.location?.pathname ?? '';
        const address = getState()?.delivery?.confirmedDeliveryAddress;

        let rebatchorderResponse;

        if (shouldRebatch) {
          await dispatch(getItems());
          rebatchorderResponse = await dispatch(rebatchOrder(address));
        }

        const successfullyRebatched = shouldRebatch && rebatchorderResponse.type === `${REBATCH_ORDER}.SUCCESS`;
        if (!shouldRebatch || successfullyRebatched) {
          if (pathname.includes(routeConstants.DELIVERY)) {
            await dispatch(
              getDeliveryPage({
                partnerDiscountEnabled,
              }),
            );

            if (successfullyRebatched) dispatch(applyRewards());

            if (address) {
              await dispatch(refreshDeliveryMethods());
            }
          }

          const { collectionPointSearchTerm, selectedCollectionPoint, collectionPointSearchComplete } =
          getState().clickAndCollect || {};

          if (pathname.includes(routeConstants.CLICK_AND_COLLECT)) {
            await dispatch(
              getDeliveryPage({
                partnerDiscountEnabled,
              }),
            );

            if (successfullyRebatched) dispatch(applyRewards());

            if (collectionPointSearchTerm && collectionPointSearchComplete) {
              await dispatch(findCollectionPoint(collectionPointSearchTerm, true));
            }
          }

          if (selectedCollectionPoint) {
            await dispatch(
              selectCollectionPoint({
                collectionPoint: selectedCollectionPoint,
                refresh: true,
              }),
            );
          }
        }
      }

      dispatch({ type: `${SET_PARTNER_DISCOUNT_PROCESSING}.FALSE` });
    };

export const handleInvalidPartnerDiscountCardNumber = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  await dispatch(togglePartnerDiscount());
  const state = getState();
  if (state.orderForm.isClickCollectOrder) {
    dispatch(replace(routeConstants.CLICK_AND_COLLECT_STORE_SELECTED));
  } else {
    dispatch(replace(routeConstants.DELIVERY));
  }
};
