import { AnyAction } from 'redux';

// Types
import { DeliveryOptions } from 'types/Delivery.types';

// Config
import deliveryConstants from '../../../constants/deliveryConstants';

function getSelectedFulfilmentOption(options: {
  action?: AnyAction;
  deliveryOptions: DeliveryOptions;
  selectedDeliveryChoiceId?: string;
}) {
  const { action, deliveryOptions, selectedDeliveryChoiceId } = options;

  const { clickCollectAvailable, collectPlusAvailable } = deliveryOptions;

  if (!clickCollectAvailable && !collectPlusAvailable) {
    // only possible choice as collection is unavailable
    return deliveryConstants.DELIVERY;
  }

  if (!selectedDeliveryChoiceId) {
    if (action?.preferredDeliveryChoice) {
      return deliveryConstants.DELIVERY;
    }

    return deliveryConstants.CLICK_AND_COLLECT;
  }

  // will return either an existing choice or the default state of undefined
  return selectedDeliveryChoiceId;
}

export default getSelectedFulfilmentOption;
